/**************** Lens Domain Model (com.vsp.api.catalogs.model, found on PatientEncounter::LabOrderInformation::Lens) ****************/
import { Link } from './link';

export interface SpectacleLens {
  externalId?: string;
  productDisplayDescription?: string;
  visionType?: LensVisionType;
  materialType?: LensMaterialType;
  design?: SpectacleLensDesign;
  specialMeasurementRequiredIndicator: boolean;
}

// TODO: May add more fields depending on what is needed for lab order
export interface SpectacleLensDesign {
  name: string;
  positionOfWearMeasurements: string;
}

/**************** Lens Finishing Types ****************/
export enum LensFinishingTypes {
  Blank = 'BLANK',
  LabFinishing = 'LAB_FINISHED_LENS',
  InOfficeStockLenses = 'IN_OFFICE_STOCK_LENS',
  InOfficeUncutLenses = 'IN_OFFICE_UNCUT_LENS'
}

export enum LensChannelNames {
  EClaim = 'ECLAIM',
  IOF = 'IOF',
  IOFExemptState = 'IOF EXEMPT STATE',
  AllAccess = 'ALL ACCESS'
}
/**************** Lens Vision Type ****************/
export interface LensVisionTypeResponse {
  items: LensVisionType[];
}

export interface LensVisionType extends LensCatalogReferenceType {
}

/**************** Lens Material Type ****************/
export interface LensMaterialTypeResponse {
  items: LensMaterialType[];
}

export interface LensMaterialType extends LensCatalogReferenceType { }


/**************** Lens Type ****************/
export interface LensCatalogReferenceType {
  externalId: number;
  name: string;
}

// Generic boxed type interface for lensLabServiceFactory, responses will be unboxed upon observer completion
export interface LensLabService { }

export interface LensCatalogResponse<T> {
  items: T[];
}

export interface LensTypeResponse {
  items: LensType[];
}

export interface LensType {
  externalId: string;
  productDescription?: string;
  self: Link;
}

export interface ScratchCoating extends LensLabService {
  brandDescription: string;
  labServiceId: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface ScratchCoatingResponse extends LensCatalogResponse<ScratchCoating> { }

export interface ARCoatingCategory {
  arCoatingCategoryLabel: string;
  arCoatings: ARCoating[];
}

export interface FormularyEnhancementCode {
  code: string;
}

export interface ARCoating extends LensLabService {
  brandDescription: string;
  brandDisplayDescription?: string;
  labServiceId: string;
  vspFormularyAssessment: {
    arAssessmentCategory: string;
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface ARCoatingResponse extends LensCatalogResponse<ARCoating> { }

export interface TintColor extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface TintColorsResponse extends LensCatalogResponse<TintColor> { }

export interface MirrorSkiCoating extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    miscellaneousAssessmentCategory: string;
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface MirrorSkiCoatingCategory {
  mirrorSkiCoatingCategoryLabel: string;
  mirrorSkiCoatings: MirrorSkiCoating[];
}

export interface DyeType extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface DyeTypesResponse extends LensCatalogResponse<DyeType> { }

export interface UVCoating extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}
export interface UVCoatingResponse extends LensCatalogResponse<UVCoating> {}

export interface GlassCoating extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}
export interface GlassCoatingResponse extends LensCatalogResponse<GlassCoating> {}

export interface EdgeCoating extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}
export interface EdgeCoatingResponse extends LensCatalogResponse<EdgeCoating> {}

export interface Bevel extends LensLabService {
  labServiceId: string;
  labServiceDescription: string;
  vspFormularyAssessment: {
    formularyEnhancements: FormularyEnhancementCode[];
  };
}

export interface BevelResponse extends LensCatalogResponse<Bevel> {}

export interface LensDto {
  positionOfWear: string;
  externalId: string;
  initialLoad: boolean;
}

export enum LensCatalogOperation {
  ScratchCoatings = 'getScratchCoatings',
  ARCoatings = 'getARCoatings',
  TintColors = 'getTintColors',
  MirrorSkiCoatings = 'getMirrorSkiCoatings',
  DyeTypes = 'getDyeTypes',
  UVCoatings = 'getUVCoatings',
  GlassCoatings = 'getGlassCoatings',
  EdgeCoatings = 'getEdgeCoatings',
  Bevels = 'getBevels',
  VisionTypes = 'getVisionTypes',
  MaterialTypes = 'getMaterialTypes',
}

export enum ARCoatingDropdownValue {
  Endura = 'Endura'
}

export enum RightLeftEnum {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT'
}

export enum CustomizableLensType {
  Required = 'Required',
  Optional = 'Optional',
  No = 'No'
}

/**************** One Lens Types ****************/
export enum OneLensTypes {
  rightLensOnly = 'Right Lens Only',
  leftLensOnly = 'Left Lens Only'
}

/**************** Balance Lens Types ****************/
export enum BalanceLensTypes {
  rightIsBalance = 'Right is Balance',
  leftIsBalance = 'Left is Balance'
}

/**************** Dye Details Types ****************/
export enum DyeDetailsTypes {
  NO_SAMPLE = 'No Sample',
  MATCH_SAMPLE = 'Match Sample',
  LIGHTEN = 'Lighten',
  DARKEN = 'Darken'
}

<app-component-mask></app-component-mask>
<div id="{{id}}">
  <!-- Prescription Card - White Container Outline -->
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': prescriptionForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="prescription-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': prescriptionForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="prescription-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': prescriptionForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="prescription-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Content -->
    <mat-card-content>
      <form [formGroup]="prescriptionForm" novalidate fxLayout="column">
        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In Prescription Card - Right Card Headers(Sphere,Cylinder,Axis & Add Section ) -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Right Title -->
          <div class="nested-column-1">
          </div>
          <!-- Right Eye Sphere Section -->
          <div class="nested-column-2">
            <label id="prescription-sphere-right-label">Sphere</label>
          </div>
          <!-- Right Eye Cylinder Section -->
          <div class="nested-column-2">
            <label id="prescription-cylinder-right-label">Cylinder</label>
          </div>
          <!-- Right Eye Axis Section -->
          <div class="nested-column-2">
            <label id="prescription-axis-right-label">Axis</label>
          </div>
          <!-- Right Eye Add Section -->
          <div class="nested-column-2">
            <label id="prescription-add-right-label">Add</label>
          </div>
          <div class="nested-column-3"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Row In Prescription Card - Right Card Headers(Sphere,Cylinder,Axis & Add Section ) -->
        <div fxLayout="row">
          <!-- Right Title -->
          <div class="nested-column-1 margin-top-20">
            <label id="prescription-right-label">Right</label>
          </div>
          <!-- Right Eye Sphere Section -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionRightEyeSphereInput"
                     formControlName="prescriptionRightEyeSphere"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.sphereRight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_SPHERE), meta.formControlMaxLength.sphereRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeSphereError"
                               [config]="errorWrapperConfig.prescriptionRightEyeSphere"></app-error-wrapper>
          </div>
          <!-- Right Eye Cylinder Section -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionRightEyeCylinderInput"
                     formControlName="prescriptionRightEyeCylinder"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.cylinderRight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_CYLINDER), meta.formControlMaxLength.cylinderRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeCylinderError"
                               [config]="errorWrapperConfig.prescriptionRightEyeCylinder"></app-error-wrapper>
          </div>
          <!-- Right Eye Axis Section -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionRightEyeAxisInput"
                     formControlName="prescriptionRightEyeAxis"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.axisRight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_AXIS), meta.formControlMaxLength.axisRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeAxisError"
                               [config]="errorWrapperConfig.prescriptionRightEyeAxis"></app-error-wrapper>
          </div>
          <!-- Right Eye Add Section -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionRightEyeAddInput"
                     formControlName="prescriptionRightEyeAdd"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.addRight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_ADD), meta.formControlMaxLength.addRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeAddError"
                               [config]="errorWrapperConfig.prescriptionRightEyeAdd"></app-error-wrapper>
          </div>
          <div class="nested-column-3"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In Prescription Card - Left Eye Headers(Sphere,Cylinder,Axis & Add Section ) -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Spacer -->
          <div class="nested-column-1"></div>
          <!-- Left Eye Sphere Header -->
          <div class="nested-column-2">
            <label id="prescription-sphere-left-label">Sphere</label>
          </div>
          <!-- Left Eye Cylinder Header -->
          <div class="nested-column-2">
            <label id="prescription-cylinder-left-label">Cylinder</label>
          </div>
          <!-- Left Eye Axis Header -->
          <div class="nested-column-2">
            <label id="prescription-axis-left-label">Axis</label>
          </div>
          <!-- Left Eye Add Header -->
          <div class="nested-column-2">
            <label id="prescription-add-left-label">Add</label>
          </div>
          <div class="nested-column-3"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Third Row In Prescription Card - Left Eye Input Boxes(Sphere,Cylinder, Axis & Add Input Section) -->
        <div fxLayout="row">
          <!-- Left Title -->
          <div class="nested-column-1 margin-top-15">
            <label id="prescription-left-label">Left</label>
          </div>
          <!-- Left Eye Sphere Section -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionLeftEyeSphereInput"
                     formControlName="prescriptionLeftEyeSphere"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.sphereLeft"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_SPHERE), meta.formControlMaxLength.sphereLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeSphereError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeSphere"></app-error-wrapper>
          </div>
          <!-- Left Eye Cylinder Input -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionLeftEyeCylinderInput"
                     formControlName="prescriptionLeftEyeCylinder"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.cylinderLeft"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_CYLINDER), meta.formControlMaxLength.cylinderLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeCylinderError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeCylinder"></app-error-wrapper>
          </div>
          <!-- Left Eye Axis Input -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionLeftEyeAxisInput"
                     formControlName="prescriptionLeftEyeAxis"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.axisLeft"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_AXIS), meta.formControlMaxLength.axisLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeAxisError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeAxis"></app-error-wrapper>
          </div>
          <!-- Left Eye Add Input -->
          <div fxLayout="column" class="nested-column-2 margin-top-15">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescriptionLeftEyeAddInput"
                     formControlName="prescriptionLeftEyeAdd"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.addLeft"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_ADD), meta.formControlMaxLength.addLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeAddError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeAdd"></app-error-wrapper>
          </div>
          <div class="nested-column-3"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fourth Row In Prescription Card - Binocular/Monocular/Distance/Near Headers -->
        <div fxLayout="row" class="margin-top-30">
          <div class="nested-column-2"></div>
          <!-- Binocular/Monocular Title -->
          <div class="nested-column-3">
            <label id="prescription-binocular-monocular-label">Binocular/Monocular</label>
          </div>
          <div *ngIf="!binocularSelected" class="nested-column-1"></div>
          <div class="nested-column-2">
            <label for="prescriptionRightEyeDistanceInput" id="prescription-right-eye-distance-label">Distance</label>
          </div>
          <div class="nested-column-2">
            <label for="prescriptionRightEyeDistanceInput" id="prescription-right-eye-near-label">Near</label>
          </div>
          <div [ngClass]="binocularSelected ? 'nested-column-3' : 'nested-column-2'"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fifth Row In Prescription Card - Distance/Near dynamic Input Boxes-->
        <div fxLayout="row" class="margin-top-15">
          <!-- Plano Lens Checkbox -->
          <div class="nested-column-2 margin-top-5">
            <mat-checkbox formControlName="planoLensInd" id="prescription-plano-lenses-label-checkbox"
                          (change)="onPlanoLensesCheckBoxChange()"
                          (keyup.enter)="onKeypressEventCheckbox( prescriptionForm.get(prescriptionConstants.PLANO_INDICATOR))">Plano Lenses
            </mat-checkbox>
          </div>
          <!-- Binocular/Monocular Dropdown -->
          <div class="nested-column-3">
            <select matSelect #prescriptionBinocularMonocularControl
                    formControlName="prescriptionBinocularMonocular" class="custom-select"
                    id="prescriptionBinocularMonocularSelect"
                    (change)="onPrescriptionBinocularMonocularChange()">
              <option *ngFor="let binMin of binocularMonocularOptions"
                      [value]="binMin.value">{{binMin.label}}</option>
            </select>
          </div>
          <!-- Dynamic Input Right Label for Monocular PD -->
          <div *ngIf="!binocularSelected" class="nested-column-1 margin-top-5">
            <label id="prescription-monocular-r-label">Right</label>
          </div>
          <!-- Distance Input Box -->
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionRightEyeDistanceControl
                     id="prescriptionRightEyeDistanceInput"
                     name="prescriptionRightEyeDistance"
                     [maxLength]="meta.formControlMaxLength.distanceRight"
                     formControlName="prescriptionRightEyeDistance"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_DISTANCE), meta.formControlMaxLength.distanceRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeDistanceError"
                               [config]="errorWrapperConfig.prescriptionRightEyeDistance"></app-error-wrapper>
          </div>
          <!-- Near Input Box -->
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionRightEyeNearControl
                     id="prescriptionRightEyeNearInput"
                     name="prescriptionRightEyeNear"
                     type="text"
                     [maxLength]="meta.formControlMaxLength.nearRight"
                     formControlName="prescriptionRightEyeNear"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_NEAR), meta.formControlMaxLength.nearRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeNearError"
                               [config]="errorWrapperConfig.prescriptionRightEyeNear"></app-error-wrapper>
          </div>
          <!-- Spacer -->
          <div [ngClass]="binocularSelected ? 'nested-column-3' : 'nested-column-2'"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Sixth Row in Prescription Card for Dynamic Fields for Monocular Left Eye Distance/Near Header -->
        <div *ngIf="!binocularSelected" fxLayout="row" class="margin-top-15">
          <!-- Binocular/Monocular Title -->
          <div class="nested-column-6"></div>
          <div class="nested-column-2">
            <label for="prescriptionRightEyeDistanceInput" id="prescription-left-eye-distance-label">Distance</label>
          </div>
          <div class="nested-column-2">
            <label for="prescriptionRightEyeDistanceInput" id="prescription-left-eye-near-label">Near</label>
          </div>
          <div class="nested-column-2"></div>
        </div>

        <!-- Dynamic Fields for Monocular Left Eye Input Boxes for Distance/Near -->
        <div *ngIf="!binocularSelected" fxLayout="row" class="margin-top-15">
          <div class="nested-column-5"></div>
          <!-- Dynamic Input Left Label for Monocular PD -->
          <div class="nested-column-1 margin-top-5">
            <label id="prescription-monocular-l-label">Left</label>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionLeftEyeDistanceControl
                     id="prescriptionLeftEyeDistanceInput"
                     name="prescriptionLeftEyeDistance"
                     type="text"
                     [maxLength]="meta.formControlMaxLength.distanceLeft"
                     formControlName="prescriptionLeftEyeDistance"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_DISTANCE), meta.formControlMaxLength.distanceLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeDistanceError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeDistance"></app-error-wrapper>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionLeftEyeNearControl
                     id="prescriptionLeftEyeNearInput"
                     name="prescriptionLeftEyeNear"
                     type="text"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.nearLeft"
                     formControlName="prescriptionLeftEyeNear"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_NEAR), meta.formControlMaxLength.nearLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeNearError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeNear"></app-error-wrapper>
          </div>
          <div class="nested-column-2"></div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Seventh Row In Prescription Card - Segment and Optical Center Height -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Segment and Optical Center Height -->
          <div class="nested-column-4">
            <label id="prescription-segment-height-label">Segment Height</label>
          </div>
          <div class="nested-column-1">
            <label id="prescription-segment-R-label">Right</label>
          </div>
          <div class="nested-column-1">
            <label id="prescription-segment-L-label">Left</label>
          </div>
          <div class="nested-column-4">
            <label id="prescription-center-height-label">Optical Center Height</label>
          </div>
          <div class="nested-column-1">
            <label id="prescription-optical-center-height-R-label">Right</label>
          </div>
          <div class="nested-column-1">
            <label id="prescription-optical-center-height-L-label">Left</label>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Eight Row In Prescription Card - Binocular/Monocular Input Input Boxes -->
        <div fxLayout="row" class="margin-top-15">
          <!-- Segment and Optical Center Height -->
          <div class="nested-column-4">
            <select matSelect
                    formControlName="prescriptionSegmentHeightStartingPoint"
                    class="custom-select form-control"
                    id="prescriptionSegmentHeightStartingPointSelect"
                    (change)="prescriptionFormFieldUpdate($event)">
              <option [value]=""></option>
              <option *ngFor="let segmentHeightDropdownOption of segmentHeightDropdown"
                      [value]="segmentHeightDropdownOption.value">{{segmentHeightDropdownOption.label}}</option>
            </select>
          </div>
          <div fxLayout="column" class="nested-column-1">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionRightEyeSegmentHeightControl
                     formControlName="prescriptionRightEyeSegmentHeight"
                     [maxLength]="meta.formControlMaxLength.segmentHeightRight"
                     [min]="0"
                     id="prescriptionRightEyeSegmentHeightInput"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_SEGMENT_HEIGHT), meta.formControlMaxLength.segmentHeightRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescription-right-eye-segment-height-error"
                               [config]="errorWrapperConfig.prescriptionRightEyeSegmentHeight"></app-error-wrapper>
          </div>
          <div fxLayout="column" class="nested-column-1">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     #prescriptionLeftEyeSegmentHeightControl
                     formControlName="prescriptionLeftEyeSegmentHeight"
                     [maxLength]="meta.formControlMaxLength.segmentHeightLeft"
                     [min]="0"
                     id="prescriptionLeftEyeSegmentHeightInput"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_SEGMENT_HEIGHT), meta.formControlMaxLength.segmentHeightLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescription-left-eye-segment-height-error"
                               [config]="errorWrapperConfig.prescriptionLeftEyeSegmentHeight"></app-error-wrapper>
          </div>
          <div class="nested-column-4">
            <select matSelect #prescriptionOpticalCenterHeightStartingPointControl
                    formControlName="prescriptionOpticalCenterHeightStartingPoint"
                    class="custom-select form-control"
                    id="prescriptionOpticalCenterHeightStartingPointSelect"
                    (change)="prescriptionFormFieldUpdate($event)">
              <option value=""></option>
              <option *ngFor="let opticalCenterHeightOption of opticalCenterHeightDropdown"
                      [value]="opticalCenterHeightOption.value">{{opticalCenterHeightOption.label}}</option>
            </select>
          </div>
          <div fxLayout="column" class="nested-column-1">
            <mat-form-field appearance="outline" fxFill>
              <input matInput #prescriptionRightEyeOpticalCenterHeightControl
                     id="prescriptionRightEyeOpticalCenterHeightInput"
                     name="prescriptionRightEyeOpticalCenterHeight"
                     type="text"
                     [maxLength]="meta.formControlMaxLength.opticalCenterRight"
                     formControlName="prescriptionRightEyeOpticalCenterHeight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_OPTICAL_CENTER_HEIGHT), meta.formControlMaxLength.opticalCenterRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeOpticalCenterHeightError"
                               [config]="errorWrapperConfig.prescriptionRightEyeOpticalCenterHeight"></app-error-wrapper>
          </div>
          <div fxLayout="column" class="nested-column-1">
            <mat-form-field appearance="outline" fxFill>
              <input matInput #prescriptionLeftEyeOpticalCenterHeightControl
                     id="prescriptionLeftEyeOpticalCenterHeightInput"
                     name="prescriptionLeftEyeOpticalCenterHeight"
                     type="text"
                     [maxLength]="meta.formControlMaxLength.opticalCenterLeft"
                     formControlName="prescriptionLeftEyeOpticalCenterHeight"
                     (blur)="prescriptionFormFieldUpdate($event)"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_OPTICAL_CENTER_HEIGHT), meta.formControlMaxLength.opticalCenterLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeOpticalCenterHeightError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeOpticalCenterHeight"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Ninth Row In Prescription Card - Prism,Base,Safety Thickness Headers - Right Eye -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Prism,Base,Safety Thickness Section -->
          <div class="nested-column-1"></div>
          <div class="nested-column-2">
            <label id="prescription-right-h-prism-label">Horizontal Prism</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-right-h-base-label">Horizontal Base</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-right-v-prism-label">Vertical Prism</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-right-v-base-label">Vertical Base</label>
          </div>
          <div class="nested-column-3">
            <label id="prescription-safety-thickness-label">Safety Thickness</label>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Tenth Row In Prescription Card - Prism,Base,Safety Thickness - Right Input Boxes -->
        <div fxLayout="row" class="margin-top-15">
          <!-- Prism,Base,Safety Thickenss Section -->
          <div class="nested-column-1 margin-top-5">
            <label id="prescription-right-two-label">Right</label>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescription-horizontal-prism-right-textbox"
                     formControlName="prescriptionRightEyeHPrism"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.horizontalPrismRight"
                     [value]="precedingZeroForDecimalPipe(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_HPRISM))"
                     (blur)="twoDecimalPlaceFormat(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_HPRISM))"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_HPRISM), meta.formControlMaxLength.horizontalPrismRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeHPrismError"
                               [config]="errorWrapperConfig.prescriptionRightEyeHPrism"></app-error-wrapper>
          </div>
          <div class="nested-column-2">
            <select matSelect formControlName="prescriptionRightEyeHBase" class="custom-select" id="hBase-Right">
              <option></option>
              <option *ngFor="let hPrism of horizontalPrismTypes"
                      [value]="hPrism.value">{{hPrism.label}}</option>
            </select>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescription-vertical-prism-right-textbox"
                     formControlName="prescriptionRightEyeVPrism"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.verticalPrismRight"
                     [value]="precedingZeroForDecimalPipe(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_VPRISM))"
                     (blur)="twoDecimalPlaceFormat(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_VPRISM))"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_RIGHT_VPRISM), meta.formControlMaxLength.verticalPrismRight, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionRightEyeVPrismError"
                               [config]="errorWrapperConfig.prescriptionRightEyeVPrism"></app-error-wrapper>
          </div>
          <div class="nested-column-2">
            <select matSelect formControlName="prescriptionRightEyeVBase" class="custom-select" id="vBase-Right">
              <option></option>
              <option *ngFor="let vPrism of verticalPrismTypes"
                      [value]="vPrism.value">{{vPrism.label}}</option>
            </select>
          </div>
          <div class="nested-column-3">
            <select matSelect formControlName="prescriptionSafetyThickness" class="custom-select"
                    id="prescription-safetyThickness">
              <option></option>
              <option *ngFor="let safetyThickness of safetyThicknessOptions"
                      [value]="safetyThickness.value">{{safetyThickness.label}}</option>
            </select>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Eleventh Row In Prescription Card - Specified Thickness Header  -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Specified Thickness Header -->
          <div class="nested-column-1"></div>
          <div class="nested-column-2">
            <label id="prescription-left-h-prism-label">Horizontal Prism</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-left-h-base-label">Horizontal Base</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-left-v-prism-label">Vertical Prism</label>
          </div>
          <div class="nested-column-2">
            <label id="prescription-left-v-base-label">Vertical Base</label>
          </div>
          <div class="nested-column-3">
            <label id="prescription-specified-thickness-label">Specified Thickness</label>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Twelfth Row In Prescription Card - Prism,Base,Specified Thickness - Left Eye Input Boxes  -->
        <div fxLayout="row" class="margin-top-15">
          <div class="nested-column-1 margin-top-5">
            <!-- Specified Thickness Header -->
            <label id="prescription-left-two-label">Left</label>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescription-horizontal-prism-left-textbox"
                     formControlName="prescriptionLeftEyeHPrism"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.horizontalPrismLeft"
                     [value]="precedingZeroForDecimalPipe(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_HPRISM))"
                     (blur)="twoDecimalPlaceFormat(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_HPRISM))"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_HPRISM), meta.formControlMaxLength.horizontalPrismLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeHPrismError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeHPrism"></app-error-wrapper>
          </div>
          <div class="nested-column-2">
            <select matSelect formControlName="prescriptionLeftEyeHBase" class="custom-select" id="hBase-Left">
              <option></option>
              <option *ngFor="let hPrism of horizontalPrismTypes"
                      [value]="hPrism.value">{{hPrism.label}}</option>
            </select>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="prescription-vertical-prism-left-textbox"
                     formControlName="prescriptionLeftEyeVPrism"
                     class="form-control"
                     [maxLength]="meta.formControlMaxLength.verticalPrismLeft"
                     [value]="precedingZeroForDecimalPipe(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_VPRISM))"
                     (blur)="twoDecimalPlaceFormat(prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_VPRISM))"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_LEFT_VPRISM), meta.formControlMaxLength.verticalPrismLeft, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
            <app-error-wrapper id="prescriptionLeftEyeVPrismError"
                               [config]="errorWrapperConfig.prescriptionLeftEyeVPrism"></app-error-wrapper>
          </div>
          <div class="nested-column-2">
            <select matSelect formControlName="prescriptionLeftEyeVBase" class="custom-select" id="vBase-Left">
              <option></option>
              <option *ngFor="let vPrism of verticalPrismTypes"
                      [value]="vPrism.value">{{vPrism.label}}</option>
            </select>
          </div>
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline" fxFill>
              <input matInput
                     id="prescription-specified-thickness-textbox"
                     formControlName="prescriptionSpecifiedThickness"
                     class="form-control"
                     [maxLength]="3"
                     (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                     (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PRESCRIPTION_SPECIFIED_THICKNESS), meta.formControlMaxLength.prescriptionSpecifiedThickness, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                     (drop)="ignoreDrop($event)">
            </mat-form-field>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Thirteenth Row In Prescription Card - Lab Special Instructions Header -->
        <div fxLayout="row" class="margin-top-30">
          <!-- Specified Thickness Header -->
          <div class="nested-column-3">
            <label id="prescription-lab-special-instructions-label" class="lab-special-instructions-label">Lab Special
              Instructions</label>
          </div>
          <div class="nested-column-9"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fourteenth Row In Prescription Card - Lab Special Instructions Checkbox and Text -->
        <div fxLayout="row" class="margin-top-15">
          <!-- Lab Special Instructions Checkbox -->
          <div class="nested-column-12">
            <mat-checkbox formControlName="labSpecialInstructionIndicator" id="lab-special-instructions-checkbox"
                          (change)="onLabSpecialInstructionsCheckboxChange()"
                          (keyup.enter)="onKeypressEventCheckbox(prescriptionForm.get(prescriptionConstants.LAB_SPECIAL_INSTRUCTIONS_INDICATOR))"></mat-checkbox>
            <label id="prescription-special-instructions-message" class="margin-left-15">Selecting special instructions
              may delay your order. Please enter special instructions only intended for the lab in this field regarding
              this order.</label>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fifteenth Row In Prescription Card - Lab Special Instructions - Input Box -->
        <div fxLayout="row" class="margin-top-30">
          <div class="nested-column-3">
            <select matSelect formControlName="labSpecialInstructionSelect" class="custom-select  margin-bottom-30"
                    id="lab-special-instructions"
                    (change)="labSpecialInstructionSelectChange()"
                    *ngIf="prescriptionForm.controls['labSpecialInstructionIndicator'].value">
              <option [value]=""></option>
              <option *ngFor="let labDescriptionTypeDropdownOption of labDescriptionTypeDropdown"
                      [value]="labDescriptionTypeDropdownOption.value">{{labDescriptionTypeDropdownOption.label}}</option>
            </select>
          </div>
          <!-- Second Dropdown - Order Section -->
          <div class="nested-column-1  margin-bottom-30" *ngIf="prescriptionForm.controls['labSpecialInstructionSelect'].value === labDescriptionTypeEnum.REDO &&
               prescriptionForm.controls['labSpecialInstructionIndicator'].value">
            <!-- Order field Header -->
            <label id="lab-special-instruction-redo-order-label">Order<span
              class="required-label-asterisk">*</span></label>
          </div>

          <div fxLayout="column" class="nested-column-3" *ngIf="prescriptionForm.controls['labSpecialInstructionSelect'].value === labDescriptionTypeEnum.REDO &&
               prescriptionForm.controls['labSpecialInstructionIndicator'].value">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text"
                     id="labSpecialInstructionRedoOrderInput"
                     formControlName="labSpecialInstructionRedoOrder"
                     autocomplete="off"
                     [maxLength]="maxLabSpecialInstructionOrderLength"/>
            </mat-form-field>
            <app-error-wrapper id="lab-special-instruction-redo-order-input-error-msg"
                               [config]="errorWrapperConfig.labSpecialInstructionRedoOrder"></app-error-wrapper>
          </div>
          <!-- Third Dropdown Other Input Box -->
          <div fxLayout="column" class="nested-column-3  margin-bottom-30" *ngIf="prescriptionForm.controls['labSpecialInstructionSelect'].value === labDescriptionTypeEnum.OTHER &&
          prescriptionForm.controls['labSpecialInstructionIndicator'].value">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text"
                     id="lab-special-instruction-other-input"
                     formControlName="labSpecialInstructionDetails"
                     autocomplete="off"
                     [maxLength]="100"/>
            </mat-form-field>
          </div>

          <!-- Special Measurements Input Box -->
          <div fxLayout="column" class="nested-column-3  margin-bottom-30" *ngIf="prescriptionForm.controls['labSpecialInstructionSelect'].value === labDescriptionTypeEnum.SPECIAL_MEASUREMENT &&
          prescriptionForm.controls['labSpecialInstructionIndicator'].value">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text"
                     id="lab-special-instructions-special-measurement-input"
                     formControlName="labSpecialInstructionSpecialMeasurementDetails"
                     autocomplete="off"
                     [maxLength]="100"/>
            </mat-form-field>
            <app-error-wrapper id="lab-special-instructions-special-measurement-input-error-msg"
                               [config]="errorWrapperConfig.labSpecialInstructionSpecialMeasurementDetails"></app-error-wrapper>
          </div>

          <div class="nested-column-2"></div>
        </div>

        <!-- Second Dropdown -->
        <div fxLayout="row" class="margin-bottom-30" *ngIf="prescriptionForm.controls['labSpecialInstructionSelect'].value === labDescriptionTypeEnum.REDO &&
               prescriptionForm.controls['labSpecialInstructionIndicator'].value">
          <div class="nested-column-3"></div>
          <div class="nested-column-1">
            <label id="lab-special-instruction-redo-details-label">Details</label>
          </div>
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text"
                     id="lab-special-instruction-redo-details-input"
                     formControlName="labSpecialInstructionDetails"
                     autocomplete="off"
                     [maxLength]="100"/>
            </mat-form-field>
          </div>
          <div class="nested-column-5"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Prescription Card - Customizable Lens Section -->
        <div *ngIf="isCustomizableLens" fxLayout="row">
          <!-- Customizable Lens Header -->
          <div class="nested-column-12">
            <label id="prescription-customizable-progressive-measurements-label">Customizable Progressive
              Measurements</label>
          </div>
        </div>

        <!-- Customizable Lens Section - Sub Headers-->
        <div *ngIf="isCustomizableLens" fxLayout="row" class="margin-top-15">
          <!-- Frame wrap-->
          <div class="nested-column-3">
            <label id="prescription-frame-wrap-label">Frame Wrap (in degrees)</label>
          </div>
          <!-- Pantoscopic tilt -->
          <div class="nested-column-3">
            <label id="prescription-pantoscopic-tilt-label">Pantoscopic Tilt (in degrees)</label>
          </div>
          <!-- Vertex -->
          <div class="nested-column-3">
            <label id="prescription-vertex-label">Vertex (in millimeters)</label>
          </div>
          <div class="nested-column-3"></div>
        </div>

        <!-- Customizable Lens Section - Input Boxes-->
        <div *ngIf="isCustomizableLens" fxLayout="row" class="margin-top-15 margin-bottom-30">
          <!-- Frame wrap Input Box-->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline" fxFill>
              <input
                matInput
                id="prescription-wrap-angle"
                formControlName="wrapAngle"
                [maxLength]="rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength"
                (blur)="decimalPipeWrapper(prescriptionForm.get(prescriptionConstants.WRAP_ANGLE), wrapAngleMinLength, wrapAngleMaxLength)"
                (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.WRAP_ANGLE), meta.formControlMaxLength.wrapAngle, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                (drop)="ignoreDrop($event)"/>
            </mat-form-field>
<!--            <app-error-wrapper id="prescription-customizable-lens-wrap-angle-error"-->
<!--                               [config]="errorWrapperConfig.wrapAngle"></app-error-wrapper>-->
          </div>
          <!-- Pantoscopic tilt Input Box -->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline" fxFill>
              <input
                matInput
                id="prescription-pantoscopic-tilt"
                formControlName="pantoscopicTilt"
                [maxLength]="rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength"
                (blur)="decimalPipeWrapper(prescriptionForm.get(prescriptionConstants.PANTOSCOPIC_TILT), pantoMinLength, pantoAndVertexMaxLength)"
                (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.PANTOSCOPIC_TILT), meta.formControlMaxLength.pantoscopicTilt, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                (drop)="ignoreDrop($event)"/>
            </mat-form-field>
<!--            <app-error-wrapper id="prescription-customizable-lens-wrap-pantoscopic-tilt"-->
<!--                               [config]="errorWrapperConfig.pantoscopicTilt"></app-error-wrapper>-->
          </div>
          <!-- Vertex Input Box -->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline" fxFill>
              <input
                matInput
                id="prescription-vertex"
                formControlName="backVertexDistance"
                [maxLength]="rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength"
                (blur)="decimalPipeWrapper(prescriptionForm.get(prescriptionConstants.BACK_VERTEX_DISTANCE), vertexMinLength, pantoAndVertexMaxLength)"
                (keypress)="fieldKeyPress($event, meta.regexp.prescriptionFieldsKeypressRegex)"
                (paste)="fieldPaste($event, prescriptionForm.get(prescriptionConstants.BACK_VERTEX_DISTANCE), meta.formControlMaxLength.backVertexDistance, meta.regexp.prescriptionFieldsPasteFilterRegex)"
                (drop)="ignoreDrop($event)"/>
            </mat-form-field>
<!--            <app-error-wrapper id="prescription-customizable-lens-wrap-vertex-distance"-->
<!--                               [config]="errorWrapperConfig.backVertexDistance"></app-error-wrapper>-->
          </div>
          <div class="nested-column-3"></div>
        </div>
        <!-- ############################################################################################################################################################################## -->

      </form>
    </mat-card-content>
  </mat-card>
</div>

import { Injectable } from '@angular/core';
import { AppDataService } from '../../http/http-client-data/app-data/app-data.service';
import { Observable, Subscriber } from 'rxjs';
import {
  ARCoating,
  LensMaterialType,
  LensType,
  LensVisionType,
  ScratchCoating,
  TintColor,
  MirrorSkiCoating,
  LensCatalogResponse,
  LensCatalogOperation,
  LensLabService,
  DyeType,
  UVCoating,
  GlassCoating,
  EdgeCoating,
  Bevel,
  SpectacleLens,
  LensChannelNames,
} from '../../../../../models/lens';
import { LensCatalogDataService } from '../../http/http-client-data/lens-catalog-data/lens-catalog-data.service';
import { ApplicationConstants } from '../../../../constants/application.constants';
import { HttpParams } from '@angular/common/http';
import {isNullOrUndefined} from '../../../../utility';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LensService {


  /***** START - PRIVATE MEMBERS *****/
  private _lensVisionTypes: LensVisionType[];
  private _lensMaterialTypes: LensMaterialType[];
  private _lensTypes: LensType[];
  private _scratchCoatings: ScratchCoating[];
  private _arCoatings: ARCoating[];
  private _tintColors: TintColor[];
  private _mirrorSkiCoatings: MirrorSkiCoating[];
  private _dyeTypes: DyeType[];
  private _uvCoatings: UVCoating[];
  private _glassCoatings: GlassCoating[];
  private _edgeCoatings: EdgeCoating[];
  private _bevels: Bevel[];
  private _spectacleLens: SpectacleLens;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
    // Per ECLAIM-269 we will be adding this Behavior Subject to be used in the 'SoftAndHardEditBannerComponent' component.
  onShowLensExpiredEdit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // Per ECLAIM-286 we are adding this behavior subject, so we can communicate with the prescription card.
  onAddSpecialMeasurementItem: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onRemoveSpecialMeasurementItem: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /***** END - PUBLIC MEMBERS *****/

  constructor(
    private appDataService: AppDataService,
    private lensCatalogService: LensCatalogDataService
  ) { }


  /***** START - PROPERTY ACCESSORS *****/
  get lensVisionTypes(): LensVisionType[] {
    return (this._lensVisionTypes) ? JSON.parse(JSON.stringify(this._lensVisionTypes)) : this._lensVisionTypes;
  }

  set lensVisionTypes(items: LensVisionType[]) {
    this._lensVisionTypes = items;
  }

  get lensMaterialTypes(): LensMaterialType[] {
    return (this._lensMaterialTypes) ? JSON.parse(JSON.stringify(this._lensMaterialTypes)) : this._lensMaterialTypes;
  }

  set lensMaterialTypes(items: LensMaterialType[]) {
    this._lensMaterialTypes = items;
  }

  get lensTypes(): LensType[] {
    return (this._lensTypes) ? JSON.parse(JSON.stringify(this._lensTypes)) : this._lensTypes;
  }

  set lensTypes(items: LensType[]) {
    this._lensTypes = items;
  }

  get scratchCoatings(): ScratchCoating[] {
    return (this._scratchCoatings) ? JSON.parse(JSON.stringify(this._scratchCoatings)) : this._scratchCoatings;
  }

  set scratchCoatings(items: ScratchCoating[]) {
    this._scratchCoatings = items;
  }

  get arCoatings(): ARCoating[] {
    return (this._arCoatings) ? JSON.parse(JSON.stringify(this._arCoatings)) : this._arCoatings;
  }

  set arCoatings(items: ARCoating[]) {
    this._arCoatings = items;
  }

  get tintColors(): TintColor[] {
    return (this._tintColors) ? JSON.parse(JSON.stringify(this._tintColors)) : this._tintColors;
  }

  set tintColors(items: TintColor[]) {
    this._tintColors = items;
  }

  get mirrorSkiCoatings(): MirrorSkiCoating[] {
    return (this._mirrorSkiCoatings) ? JSON.parse(JSON.stringify(this._mirrorSkiCoatings)) : this._mirrorSkiCoatings;
  }

  set mirrorSkiCoatings(items: MirrorSkiCoating[]) {
    this._mirrorSkiCoatings = items;
  }

  get dyeTypes(): DyeType[] {
    return(this._dyeTypes) ? JSON.parse(JSON.stringify(this._dyeTypes)) : this._dyeTypes;
  }

  set dyeTypes(items: DyeType[]) {
    this._dyeTypes = items;
  }

  get uvCoatings(): UVCoating[] {
    return(this._uvCoatings) ? JSON.parse(JSON.stringify(this._uvCoatings)) : this._uvCoatings;
  }

  set uvCoatings(items: UVCoating[]) {
    this._uvCoatings = items;
  }

  get glassCoatings(): GlassCoating[] {
    return(this._glassCoatings) ? JSON.parse(JSON.stringify(this._glassCoatings)) : this._glassCoatings;
  }

  set glassCoatings(items: GlassCoating[]) {
    this._glassCoatings = items;
  }

  get edgeCoatings(): EdgeCoating[] {
    return(this._edgeCoatings) ? JSON.parse(JSON.stringify(this._edgeCoatings)) : this._edgeCoatings;
  }

  set edgeCoatings(items: EdgeCoating[]) {
    this._edgeCoatings = items;
  }

  get bevels(): Bevel[] {
    return(this._bevels) ? JSON.parse(JSON.stringify(this._bevels)) : this._bevels;
  }

  set bevels(items: Bevel[]) {
    this._bevels = items;
  }

  get spectacleLens(): SpectacleLens {
    return (this._spectacleLens) ? JSON.parse(JSON.stringify(this._spectacleLens)) : this._spectacleLens;
  }

  set spectacleLens(lens: SpectacleLens) {
    this._spectacleLens = lens;
  }
  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE FUNCTIONS *****/

  /**
   * Generic data layer implementation of loading data from the lens catalog API.
   *
   * @param property - member property to set
   * @param operation - typed endpoint to call
   * @param endpoint - path to retrieve lens catalog data
   * @param params - optional query parameters to pass along to the HTTP layer
   * @param isNonLabService - optional flag to call lab service
   */
  private loadLabService<T>(property: string, operation: string, endpoint: string, params?: HttpParams, isNonLabService: boolean = false): Observable<T[]> {
    return new Observable<T[]>((observer: Subscriber<T[]>) => {
      this.lensCatalogService.getLensCatalogItem<T>(operation, endpoint, params, isNonLabService).subscribe((response: LensCatalogResponse<T>) => {
        if (response) {
          if (isNullOrUndefined(response.items) || response.items.length < 1) {
            Reflect.set(this, property, undefined);
            observer.next(undefined);
          } else {
            Reflect.set(this, property, response.items);
            observer.next(response.items);
          }
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - PUBLIC METHODS *****/

  /**
   * Factory method for loading lens catalog data based on operation passed in by the caller.
   *
   * @param operation - enum operation to perform, one of the four endpoints we currently call
   * @param dateOfService - Date of service to be used to make the call
   * @param isIOFChannel - IOF Channel going to be used.
   * @return reference observable to be executed upon subscription
   */
  lensLabServiceFactory(operation: string, dateOfService: string, isIOFChannel: boolean = false): Observable<LensLabService[]> {
    let includeInactiveLabServicesParam: HttpParams = new HttpParams().set('includeinactive', 'false');

    if (!isNullOrUndefined(dateOfService)) {
      includeInactiveLabServicesParam = includeInactiveLabServicesParam.set('asOfDate', dateOfService);
    }

    if ( isIOFChannel ) {
      includeInactiveLabServicesParam = includeInactiveLabServicesParam.set('channelName', LensChannelNames.IOF);
    }

    switch (operation) {
      case LensCatalogOperation.ScratchCoatings:
        return this.loadLabService<ScratchCoating>(
          'scratchCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.scratchCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.ARCoatings:
        return this.loadLabService<ARCoating>(
          'arCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.arCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.TintColors:
        return this.loadLabService<TintColor>(
          'tintColors',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.tintColors,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.MirrorSkiCoatings:
        return this.loadLabService<MirrorSkiCoating>(
          'mirrorSkiCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.mirrorSkiCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.DyeTypes:
        return this.loadLabService<DyeType>(
          'dyeTypes',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.dyeTypes,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.UVCoatings:
        return this.loadLabService<UVCoating>(
          'uvCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.uvCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.GlassCoatings:
        return this.loadLabService<GlassCoating>(
          'glassCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.glassCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.EdgeCoatings:
        return this.loadLabService<EdgeCoating>(
          'edgeCoatings',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.edgeCoatings,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.Bevels:
        return this.loadLabService<Bevel>(
          'bevels',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.miscellaneousServices.bevels,
          includeInactiveLabServicesParam
        );
      case LensCatalogOperation.VisionTypes:
        return this.loadLabService<LensVisionType>(
          'lensVisionTypes',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.lensVisionType,
          undefined,
          true
        );
      case LensCatalogOperation.MaterialTypes:
        return this.loadLabService<LensMaterialType>(
          'lensMaterialTypes',
          operation,
          ApplicationConstants.api.lensCatalogEndpoint.lensMaterialType,
          undefined,
          true
        );
    }
  }

  loadLensVisionTypes(): Observable<LensVisionType[]> {
    return new Observable<LensVisionType[]>((observer: Subscriber<LensVisionType[]>) => {
      this.appDataService.getLensVisionTypes().subscribe((lensVisionTypeResponse) => {
        if (lensVisionTypeResponse) {
          this.lensVisionTypes = lensVisionTypeResponse.items;
          observer.next(lensVisionTypeResponse.items);
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  loadLensMaterialTypes(): Observable<LensMaterialType[]> {
    return new Observable<LensMaterialType[]>((observer: Subscriber<LensMaterialType[]>) => {
      this.appDataService.getLensMaterialTypes().subscribe((lensMaterialTypeResponse) => {
        if (lensMaterialTypeResponse) {
          this.lensMaterialTypes = lensMaterialTypeResponse.items;
          observer.next(lensMaterialTypeResponse.items);
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  loadLensTypes(channelName: string, visionType: string, materialType: string, dateOfService: Date): Observable<LensType[]> {
    return new Observable<LensType[]>((observer: Subscriber<LensType[]>) => {
      this.appDataService.getLensTypes(channelName, visionType, materialType, dateOfService).subscribe((LensTypeResponse) => {
        if (LensTypeResponse) {
          this.lensTypes = LensTypeResponse.items;
          observer.next(LensTypeResponse.items);
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  retrieveLensDetailFromLink(selfLink: string): Observable<SpectacleLens> {
    return new Observable<SpectacleLens>((observer: Subscriber<SpectacleLens>) => {
      this.lensCatalogService.retrieveLensDetail(selfLink).subscribe((spectacleLens: SpectacleLens) => {
        if (spectacleLens) {
          this.spectacleLens = spectacleLens;
          observer.next(spectacleLens);
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }
  /***** END - PUBLIC METHODS *****/
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorWrapperConfig } from '../../../common/components/error-wrapper/error-wrapper.component';
import { InputMaskService } from '../../../common/services/support/input-mask/input-mask.service';
import { LabOrderLabServices } from '../../../models/labOrderLabServices';
import {LensConstants, LensMaterials, LensVisionTypes, TintColors} from './lens.constants';
import { ClaimsService } from '../../../common/services/data-model/app/claims/claims.service';
import { ClaimFormItem } from '../../../common/classes/claim-form-item';
import { Subscription } from 'rxjs';
import { ComponentMaskComponent } from '../../../common/components/component-mask/component-mask.component';
import { ApplicationConstants, ErrorTypes, MiscellaneousServices } from '../../../common/constants/application.constants';
import { LensService } from 'src/app/common/services/data-model/app/lens/lens.service';
import {
  fieldKeyPress,
  ignoreDrop,
  fieldPaste,
  DateUtility,
  isStringNullUndefinedOrEmptyWithTrim
} from '../../../common/utility';
import {
  LensType,
  LensVisionType,
  MirrorSkiCoating,
  ScratchCoating,
  ARCoating,
  TintColor,
  ARCoatingCategory,
  LensCatalogOperation,
  DyeType,
  LensChannelNames,
  LensFinishingTypes,
  LensMaterialType,
  UVCoating,
  GlassCoating,
  EdgeCoating,
  Bevel,
  RightLeftEnum,
  SpectacleLens,
  LensDto,
  DyeDetailsTypes, MirrorSkiCoatingCategory
} from 'src/app/models/lens';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from 'src/app/models/claim';
import { ExternalServiceLocationService } from 'src/app/common/services/data-model/app/external-service-location/external-service-location.service';
import { ExternalServiceLocation } from 'src/app/models/externalServiceLocation';
import { HttpParams } from '@angular/common/http';
import {
  isEmptyList,
  setToNullOrValue,
  simpleSlugFromString,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  tryParseInt
} from '../../../common/utility';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { MessageService } from '../../../common/services/support/message/message.service';
import { ReferenceListValue } from '../../../models/reference-list-view-model';
import { LabOrderOption } from '../../../models/labOrderOption';
import { LabSpecialInstructions, LabSpecialInstructionsType } from 'src/app/models/labSpecialInstructions';
import { LensOrFrame } from '../exam/exam.constants';
import {LabOrderInformation} from '../../../models/labOrderInformation';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {CookieService} from 'ngx-cookie-service';
import {FulfillmentService} from '../../../common/services/data-model/app/fulfillment/fulfillment.service';
import {UserSessionService} from '../../../common/services/support/user-session/user-session.service';

@Component({
  selector: 'app-lens',
  templateUrl: './lens.component.html',
  styleUrls: ['./lens.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LensComponent extends ClaimFormItem implements OnInit, AfterViewInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
  private readonly _includeInactiveLabServicesParam: HttpParams;
  private _scratchCoatings: ScratchCoating[];
  private _arCoatingsCategories: ARCoatingCategory[];
  private _lensVisionTypes: LensVisionType[];
  private _lensMaterialTypes: LensMaterialType[];
  private _lensTypes: LensType[];
  private _tintColors: TintColor[];
  private _mirrorSkiCoatings: MirrorSkiCoating[];
  private _mirrorSkiCoatingsCategories: MirrorSkiCoatingCategory[];
  private _loadingMirrorSkiCoatings: boolean;
  private _isOtherMirrorSkiCoating: boolean;
  private _dyeTypes: DyeType[];
  private _loadingDyeTypes: boolean;
  private _uvCoatings: UVCoating[];
  private _loadingUVCoatings: boolean;
  private _glassCoatings: GlassCoating[];
  private _loadingGlassCoatings: boolean;
  private _edgeCoatings: EdgeCoating[];
  private _loadingEdgeCoatings: boolean;
  private _activeClaim: Claim;
  private _originalClaim: Claim;
  private _externalServiceLocation: ExternalServiceLocation;
  private observableSubscriptions: Subscription[] = [];
  private _bevels: Bevel[];
  private _loadingBevels: boolean;
  private _isPlanoLensesCheckBoxChecked: boolean;
  private isAMaskUpdate = false;
  private mask = true;
  private lensOrARCoatingChanged = false;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  private hasARItems = true;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('lensDropdown', {static: true}) private lensDropdown: NgSelectComponent;
  /***** END - PRIVATE MEMBERS *****/

  // Assigning functions from utility class to variables so that these can be accessed  in template
  public fieldKeyPress = fieldKeyPress;
  public ignoreDrop = ignoreDrop;
  public fieldPaste = fieldPaste;

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    public inputMaskService: InputMaskService,
    private lensService: LensService,
    private claimService: ClaimsService,
    private externalServiceLocationService: ExternalServiceLocationService,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService,
    private claimEditService: ClaimEditService,
    private cookieService: CookieService,
    private userSessionService: UserSessionService,
    private fulfillmentService: FulfillmentService
  ) {
    super(injector);
    this._includeInactiveLabServicesParam = new HttpParams().set('includeinactive', 'false');
    this._loadingMirrorSkiCoatings = false;
    this._loadingDyeTypes = false;
    this._loadingUVCoatings = false;
    this._loadingGlassCoatings = false;
    this._loadingEdgeCoatings = false;
    this._loadingBevels = false;
    this._isPlanoLensesCheckBoxChecked = false;
  }

  /***** START - PUBLIC MEMBERS *****/
  id = 'lens-section';
  title = 'Lens';
  lensForm: FormGroup;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  @ViewChild('dyeLightenDarkenPercentControl', {read: ElementRef, static: true}) dyeLightenDarkenPercentControl: ElementRef;

  // Form state/data variables
  errorWrapperConfig = {
    finishing: new ErrorWrapperConfig(),
    visionType: new ErrorWrapperConfig(),
    material: new ErrorWrapperConfig(),
    lens: new ErrorWrapperConfig(),
    dyeLightenDarkenPercent: new ErrorWrapperConfig(),
    dyeColorOther: new ErrorWrapperConfig(),
    mirrorSkiCoatingOtherDescription: new ErrorWrapperConfig(),
    rightBaseCurve: new ErrorWrapperConfig(),
    leftBaseCurve: new ErrorWrapperConfig()
  };
  selectOptions = {
    finishing: LensConstants.finishingTypes,
    oneLens: LensConstants.oneLensOptions,
    balanceLens: LensConstants.balanceLensOptions,
    dyeDetails: LensConstants.dyeDetails
  };
  loadingScratchCoatings = false;
  loadingARCoatings = false;
  loadingTintColors = false;
  loadingLens = false;
  maxGlassCoatingDescriptionLength: number = ApplicationConstants.maxGlassCoatingDescriptionLength;
  maxLensCardDescriptionLength: number = ApplicationConstants.maxLensCardDescriptionLength;
  rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength: number = ApplicationConstants.rightLeftBaseCurveAndCustomizableLensFieldsMaxLength;
  leftAndRightBaseCurveFieldsErrorMessage = 'Please enter a valid base curve value between 0 and 40.00';
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private hasAllAvailableLensCatalogData = (finishing: string, visionTypeId: string, materialTypeId: string, date: Date): boolean =>
    !isStringNullUndefinedOrEmpty(finishing) && !isNullOrUndefined(visionTypeId) && !isNullOrUndefined(materialTypeId) && !isNullOrUndefined(date)

  private buildForm(): void {
    // TODO: Add fields as we hook them up to the active claim
    let lensFinishing: string, dyeDetail: string, dyeColorOther: string, glassCoatingDescription: string, mirrorSkiCoatingDescription: string;
    let materialType: number, visionType: number, dyeLightenDarkenPercent: number, rightBaseCurveValue: number, leftBaseCurveValue: number;
    let lens: SpectacleLens;
    let oneLens: string;
    let balanceLens: string;
    let bevel: Bevel;
    let edge: EdgeCoating;
    let dyeColor: TintColor;
    let dyeType: DyeType;
    let arCoating: ARCoating;
    let uvCoating: UVCoating;
    let scratchCoating: ScratchCoating;
    let glassCoating: GlassCoating;
    let mirrorSkiCoating: MirrorSkiCoating;
    let pressOnPrismValue: string;
    let nosePadsValue: string;
    let slabOffValue: string;

    if (this.originalClaim && this.originalClaim.labOrderInformation) {
      lensFinishing = isNullOrUndefined(this.originalClaim.labOrderInformation.lensFinishing) || LensFinishingTypes.Blank === this.originalClaim.labOrderInformation.lensFinishing ? LensFinishingTypes.LabFinishing : this.originalClaim.labOrderInformation.lensFinishing;
      lens = setToNullOrValue(this.originalClaim.labOrderInformation.lens);
      rightBaseCurveValue = setToNullOrValue(this.originalClaim.labOrderInformation.lensBaseCurveRight);
      leftBaseCurveValue = setToNullOrValue(this.originalClaim.labOrderInformation.lensBaseCurveLeft);
      oneLens = setToNullOrValue(this.originalClaim.labOrderInformation.oneLens);
      balanceLens = setToNullOrValue(this.originalClaim.labOrderInformation.balanceLens);
      if (!isNullOrUndefined(this.originalClaim.labOrderInformation.pressOnPrism) && this.originalClaim.labOrderInformation.pressOnPrism.code === MiscellaneousServices.PressOnPrismCode) {
        pressOnPrismValue = setToNullOrValue(this.originalClaim.labOrderInformation.pressOnPrism.description);
      }

      if (!isNullOrUndefined(this.originalClaim.labOrderInformation.nosePads) && this.originalClaim.labOrderInformation.nosePads.code === MiscellaneousServices.NosePadsCode) {
        nosePadsValue = setToNullOrValue(this.originalClaim.labOrderInformation.nosePads.description);
      }

      if (!isNullOrUndefined(this.originalClaim.labOrderInformation.slabOff) && this.originalClaim.labOrderInformation.slabOff.code === MiscellaneousServices.SlabOffCode) {
        slabOffValue = setToNullOrValue(this.originalClaim.labOrderInformation.slabOff.description);
      }

      if (!isNullOrUndefined(lens)) {
        materialType = isNullOrUndefined(lens.materialType) ? null : setToNullOrValue(lens.materialType.externalId);
        visionType = isNullOrUndefined(lens.visionType) ? null : setToNullOrValue(lens.visionType.externalId);
      }
      bevel = this.getMatchingBevelFromOriginalClaim();
      edge = this.getMatchingEdgeCoatingFromOriginalClaim();
      dyeColor = this.getMatchingDyeColorFromOriginalClaim();
      if (!isNullOrUndefined(dyeColor) && dyeColor.labServiceId === TintColors.OTHER) {
        dyeColorOther = this.originalClaim.labOrderInformation.lensTintColor.labServiceDescription;
      }
      dyeType = this.getMatchingDyeTypeFromOriginalClaim();
      dyeDetail = isNullOrUndefined(this.originalClaim.labOrderInformation.lensTintSample) ? undefined : this.selectOptions.dyeDetails.find((item) => item === this.originalClaim.labOrderInformation.lensTintSample);
      dyeLightenDarkenPercent = isNullOrUndefined(this.originalClaim.labOrderInformation.lensTintSamplePercentage) ? undefined : this.originalClaim.labOrderInformation.lensTintSamplePercentage;
      arCoating = this.getMatchingARCoatingFromOriginalClaim();
      uvCoating = this.getMatchingUVCoatingFromOriginalClaim();
      scratchCoating = this.getMatchingScratchCoatingFromOriginalClaim();
      glassCoating = this.getMatchingGlassCoatingFromOriginalClaim();
      if (!isNullOrUndefined(glassCoating)) {
        glassCoatingDescription = this.originalClaim.labOrderInformation.lensGlassCoating.labServiceDescription;
      }
      mirrorSkiCoating = this.getMatchingMirrorSkiCoatingFromOriginalClaim();
      if (isNullOrUndefined(mirrorSkiCoating) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensMirrorSkiCoating)) {
        mirrorSkiCoatingDescription = this.originalClaim.labOrderInformation.lensMirrorSkiCoating.labServiceDescription;
      }
    } else {
      lensFinishing = LensFinishingTypes.LabFinishing;
    }

    // TODO: Initialize, if applicable, to the below form values from the lens data attached on the PE

    this.lensForm = this.formBuilder.group({
      finishing: { value: lensFinishing, disabled: false },
      visionType: { value: visionType, disabled: isStringNullUndefinedOrEmpty(lensFinishing) },
      material: { value: materialType, disabled: isNullOrUndefined(visionType) },
      lens: { value: isNullOrUndefined(lens) ? null : setToNullOrValue(lens.externalId), disabled: isNullOrUndefined(materialType) },
      oneLens: oneLens,
      rightBaseCurve: [rightBaseCurveValue, [Validators.pattern(ApplicationConstants.rightAndLeftBaseCurveRegex), Validators.min(0), Validators.max(40)]],
      balanceLens: { value: isNullOrUndefined(oneLens) ? balanceLens : null, disabled: !isNullOrUndefined(oneLens)},
      leftBaseCurve: [leftBaseCurveValue, [Validators.pattern(ApplicationConstants.rightAndLeftBaseCurveRegex), Validators.min(0), Validators.max(40)]],
      bevel: bevel,
      edge: edge,
      dyeColor: dyeColor,
      dyeColorOther: [{ value: dyeColorOther, disabled: !isNullOrUndefined(dyeColor) && dyeColor.labServiceId === TintColors.OTHER }, [Validators.required, Validators.pattern(ApplicationConstants.dyeColorOtherDescriptionRegex)]],
      dyeType: dyeType,
      dyeDetails: isNullOrUndefined(dyeDetail) ? [DyeDetailsTypes.NO_SAMPLE] : dyeDetail,
      dyeLightenDarkenPercent: [{ value: dyeLightenDarkenPercent, disabled: isNullOrUndefined(dyeLightenDarkenPercent) }, [Validators.min(1), Validators.max(99)]],
      arCoating: {value: arCoating, disabled: !isNullOrUndefined(scratchCoating)},
      uvCoating: uvCoating,
      scratchCoating: {value: scratchCoating, disabled: !isNullOrUndefined(arCoating)},
      pressOnPrismValue: [ pressOnPrismValue, [Validators.pattern(ApplicationConstants.lensValueStringRegex)]],
      glassCoating: glassCoating,
      nosePadsValue: [ nosePadsValue, [Validators.pattern(ApplicationConstants.lensValueStringRegex )]],
      glassCoatingDescription: glassCoatingDescription,
      slabOffValue: [ slabOffValue, [Validators.pattern(ApplicationConstants.lensValueStringRegex )]],
      mirrorSkiCoating: mirrorSkiCoating,
      mirrorSkiCoatingOtherDescription: [{ value: mirrorSkiCoatingDescription, disabled: !this.isOtherMirrorSkiCoating }, [Validators.required, Validators.pattern(ApplicationConstants.mirrorSkiCoatingOtherDescriptionRegex)]] // TODO: Initialize values from saved claim, if available
    });

    // Call LCAT to retrieve the list of lenses if a user has previous persisted PE data required to call LCAT
    if (!isStringNullUndefinedOrEmpty(this.finishingFormValue) && !isNullOrUndefined(this.visionTypeFormValue) && !isNullOrUndefined(this.materialFormValue)) {
      this.lensForm.controls.material.setValidators(Validators.required);
      this.lensForm.controls.lens.setValidators(Validators.required);
      if (!isNullOrUndefined(this.materialFormValue)) {
        this.loadLenses(this.originalClaim.dateOfService, this.lensForm.get('lens').value as string);
      }
    }
  }

  private getMatchingBevelFromOriginalClaim(): Bevel {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensBevel) && !isNullOrUndefined(this.bevels)) {
      return this.bevels.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensBevel.labServiceId);
    }
    return undefined;
  }

  private getMatchingEdgeCoatingFromOriginalClaim(): EdgeCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensEdge) && !isNullOrUndefined(this.edgeCoatings)) {
      return this.edgeCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensEdge.labServiceId);
    }
    return undefined;
  }

  private getMatchingDyeColorFromOriginalClaim(): TintColor {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensTintColor) && !isNullOrUndefined(this.tintColors)) {
      return this.tintColors.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensTintColor.labServiceId);
    }
    return undefined;
  }

  private getMatchingDyeTypeFromOriginalClaim(): DyeType {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensTintType) && !isNullOrUndefined(this.dyeTypes)) {
      return this.dyeTypes.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensTintType.labServiceId);
    }
    return undefined;
  }

  private getMatchingARCoatingFromOriginalClaim(): ARCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensAntiReflectiveCoating)) {
      let arCoating: ARCoating;
      this.arCoatingsCategories.forEach((category) => {
        if (arCoating) {
          return;
        }
        arCoating = category.arCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId);
      });
      return arCoating;
    }
    return undefined;
  }

  private getMatchingUVCoatingFromOriginalClaim(): UVCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensUltraVioletCoating) && !isNullOrUndefined(this.uvCoatings)) {
      return this.uvCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensUltraVioletCoating.labServiceId);
    }
    return undefined;
  }

  private getMatchingScratchCoatingFromOriginalClaim(): ScratchCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensScratchCoating) && !isNullOrUndefined(this.scratchCoatings)) {
      return this.scratchCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensScratchCoating.labServiceId);
    }
    return undefined;
  }

  private getMatchingGlassCoatingFromOriginalClaim(): GlassCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensGlassCoating) && !isNullOrUndefined(this.glassCoatings)) {
      return this.glassCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensGlassCoating.labServiceId);
    }
    return undefined;
  }

  private getMatchingMirrorSkiCoatingFromOriginalClaim(): MirrorSkiCoating {
    if (!isNullOrUndefined(this.originalClaim.labOrderInformation) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lensMirrorSkiCoating) && !isNullOrUndefined(this.mirrorSkiCoatings)) {
      return this.mirrorSkiCoatings.find((item) => item.labServiceId === this.originalClaim.labOrderInformation.lensMirrorSkiCoating.labServiceId);
    }
    return undefined;
  }

  private disableFormFields(): void {
    const materialType = isNullOrUndefined(this.lensMaterialTypes) ? undefined : this.lensMaterialTypes.find((material: LensMaterialType) => material.externalId === this.materialFormValue);
    if (!isNullOrUndefined(materialType)) {
      this.enableDisableDyeRelatedFields(materialType);
      this.enableDisableScratchCoating();
      this.enableDisableGlassCoating(materialType);
      this.enableDisableARCoating();
    }
    this.onOneLensChange();
    this.onBalanceLensChange();
    this.onPlanoLensesCheckboxChange();
    this.onTintColorChange();
    this.onDyeDetailsChange();
    if (isStringNullUndefinedOrEmpty(this.lensForm.controls.visionType.value)) {
      this.lensForm.controls.material.disable();
    }
    if (isStringNullUndefinedOrEmpty(this.lensForm.controls.material.value)) {
      this.lensForm.controls.lens.disable();
    }
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      finishing: {
        control: this.lensForm.controls.finishing,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Lens Finishing is required'
        }]
      },
      visionType: {
        control: this.lensForm.controls.visionType,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Vision Type is required for Lens Orders'
        }]
      },
      material: {
        control: this.lensForm.controls.material,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Material is required for Lens Orders'
        }]
      },
      lens: {
        selectInputField: this.lensDropdown,
        control: this.lensForm.controls.lens,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please select a lens'
        }]
      },
      dyeLightenDarkenPercent: {
        control: this.lensForm.controls.dyeLightenDarkenPercent,
        errors: [{
          validatorType: ErrorTypes.Min,
          errorMessage: 'Please enter a value between 1 and 99'
        }, {
          validatorType: ErrorTypes.Max,
          errorMessage: 'Please enter a value between 1 and 99'
        }]
      },
      dyeColorOther: {
        control: this.lensForm.get('dyeColorOther'),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a description'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid description'
        }]
      },
      mirrorSkiCoatingOtherDescription: {
        control: this.lensForm.get('mirrorSkiCoatingOtherDescription'),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a description'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid description'
        }]
      },
      rightBaseCurve: {
        control: this.lensForm.controls.rightBaseCurve,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: this.leftAndRightBaseCurveFieldsErrorMessage
        }]
      },
      leftBaseCurve: {
        control: this.lensForm.controls.leftBaseCurve,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: this.leftAndRightBaseCurveFieldsErrorMessage
        }]
      }
    };
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.dyeLightenDarkenPercentControl.nativeElement, '99',
      { clearIncomplete: false, placeholder: '' });
  }

  private initializeLensCatalogData(): void {
    this.scratchCoatings = this.lensService.scratchCoatings;
    this.arCoatingsCategories = this.getViewFriendlyARCoatings();
    if (!isNullOrUndefined(this.lensForm) && this.finishingFormValue === LensFinishingTypes.InOfficeStockLenses) {
        const {dateOfService: dateOfServiceFromService } = this.claimService.getActiveClaim();
        const dateOfServiceFromServiceString = DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFromService);
        this.reloadARCoatings(dateOfServiceFromServiceString);
    }
    this.tintColors = this.lensService.tintColors;
    this.mirrorSkiCoatingsCategories = this.getViewFriendlyMirrorSkiCoatings();
    this.dyeTypes = this.lensService.dyeTypes;
    this.uvCoatings = this.lensService.uvCoatings;
    this.glassCoatings = this.lensService.glassCoatings;
    this.edgeCoatings = this.lensService.edgeCoatings;
    this.bevels = this.lensService.bevels;
    this.lensMaterialTypes = this.lensService.lensMaterialTypes;
    this.lensVisionTypes = this.lensService.lensVisionTypes;
    this.mirrorSkiCoatings = this.lensService.mirrorSkiCoatings;
  }

  private retrieveLensAndBroadcastPositionOfWearToListeners(selectedLens: LensType, initialLoad: boolean = false): void {
    this.lensService.retrieveLensDetailFromLink(selectedLens.self.href)
      .pipe(take(1))
      .subscribe((spectacleLens: SpectacleLens) => {
        if (!isNullOrUndefined(spectacleLens)) {
          if (spectacleLens.design) {
            // Emit the position of wear for the prescription card to display customizable lens fields and validation
            this.viewStateService.setSelectedSpectacleLens({ positionOfWear: spectacleLens.design.positionOfWearMeasurements, externalId: spectacleLens.externalId, initialLoad: initialLoad } as LensDto);
          }
          // Per ECLAIM-286 we want to check the 'specialMeasurementRequiredIndicator' and let the prescription card know to add/remove the "Special Measurements" option in the "Lab Special Instructions" dropdown.
          if (spectacleLens.specialMeasurementRequiredIndicator) {
            this.lensService.onAddSpecialMeasurementItem.next(true);
          } else {
            this.lensService.onRemoveSpecialMeasurementItem.next(true);
          }
        }
      });
  }

  private loadLenses(originalDateOfService?: Date, spectacleLensId?: string) {
    const finishingType: string = this.finishingFormValue;
    const visionType: string = isNullOrUndefined(this.lensVisionTypes) ? undefined : this.lensVisionTypes.find((type: LensVisionType) => this.visionTypeFormValue === type.externalId).name;
    const materialType: string = isNullOrUndefined(this.lensVisionTypes) ? undefined : this.lensMaterialTypes.find((type: LensMaterialType) => this.materialFormValue === type.externalId).name;
    const dateOfService: Date = isNullOrUndefined(originalDateOfService) ? this.activeClaim.dateOfService : originalDateOfService;

    if (this.hasAllAvailableLensCatalogData(finishingType, visionType, materialType, dateOfService)) {
      const channelName = this.getChannelName(finishingType);
      this.lensForm.controls.lens.disable(ApplicationConstants.updateFormWithoutEmit);
      this.loadingLens = true;
      this.lensService.loadLensTypes(channelName, visionType, materialType, dateOfService).subscribe((lensTypes: LensType[]) => {
        if (lensTypes) {
          this.lensTypes = lensTypes;
          if (this.lensForm.enabled) {
            this.lensForm.controls.lens.enable(ApplicationConstants.updateFormWithoutEmit);
          }
          this.lensForm.controls.lens.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);

          // Call the lens retrieve if a lens is selected and we're building the form initially
          if (!isStringNullUndefinedOrEmpty(spectacleLensId)) {
            // Grab a reference to the persisted lens from the lens values returned from LCAT search
            const selectedLens: LensType = lensTypes.find((lens: LensType) => lens.externalId === spectacleLensId);
            if (selectedLens && selectedLens.self) {
              // Retrieve the lens and broadcast to the prescription card
              this.retrieveLensAndBroadcastPositionOfWearToListeners(selectedLens, true);
            }
          }
        }
        this.loadingLens = false;
        // Per ECLAIM-269 we want to clear out VisionType, Material and Lens fields in the claim form if Lens retrieve from PE is not inside the LCAT summaries response.
        this.clearOutLensIfNotFoundInLCATResponse(this.lensFormValue);
        this.changeDetector.detectChanges();
      });
    }
  }

  // See ECR-6783 for channel name selection logic matrix
  private getChannelName(finishingType: string): string {
    const isIOFExemptState: boolean = this.getIOFExemptStateCodes.stateCodes.includes(this.buildPracticeStateCodeFromBatchCookie());
     if (isIOFExemptState && (finishingType === LensFinishingTypes.InOfficeStockLenses)) {
       return LensChannelNames.IOFExemptState;
     }
    if (finishingType === LensFinishingTypes.InOfficeStockLenses) {
      return LensChannelNames.IOF;
    } else {
      if (this.externalServiceLocation.labNetworkOptOutInd) {
        return LensChannelNames.AllAccess;
      } else if (finishingType === LensFinishingTypes.LabFinishing || finishingType === LensFinishingTypes.InOfficeUncutLenses) {
        return LensChannelNames.EClaim;
      }
    }
  }

  private isIOFChannel(): boolean {
    let isIOFFinishing = false;
    if ( !isNullOrUndefined(this.finishingFormValue) ) {
      const channelName = this.getChannelName(this.finishingFormValue);
      if (channelName === LensChannelNames.IOF) {
        isIOFFinishing = true;
      }
    }
    return isIOFFinishing;
  }

  private updateFinishingList() {
    const index = (this.selectOptions.finishing.findIndex(finishing => finishing.value === LensFinishingTypes.InOfficeUncutLenses));
    if (!this.externalServiceLocation.uncutLensInd && index > -1) {
      // If uncutLensInd = false - Remove InOfficeUncutLenses from list
      this.selectOptions.finishing.splice(index, 1);
    } else if (this.externalServiceLocation.uncutLensInd && index === -1) {
      // If uncutLensInd = true - Add InOfficeUncutLenses to list (if missing)
      this.selectOptions.finishing.push(new ReferenceListValue(LensFinishingTypes.InOfficeUncutLenses, 'In-Office Uncut Lens').viewModel);
    }
    // Per ECLAIM-252 if the 'iofStockProgramAuthorized' flag returns false we want to remove the 'In-Office Stock Lens' value from the dropdown
    if (!isNullOrUndefined(this.externalServiceLocation) && !this.externalServiceLocation.iofStockProgramAuthorized) {
      // Get the index of the InOfficeStockLenses channel.
      const iofStockLensIndex = (this.selectOptions.finishing.findIndex(finishing => finishing.value === LensFinishingTypes.InOfficeStockLenses));
      if (iofStockLensIndex > -1) {
        // If iofStockProgramAuthorized = false - Remove InOfficeStockLenses from list
        this.selectOptions.finishing.splice(iofStockLensIndex, 1);
      }
    }
  }

  private updateDataModelFromViewModel(): void {
    // TODO: Add fields to the active claim as we go
    this.lensOrARCoatingChanged = false;
    const { finishing, visionType, material, lens, oneLens, balanceLens, bevel, edge, dyeColor, dyeColorOther, dyeType, dyeDetails, dyeLightenDarkenPercent,
      arCoating, uvCoating, scratchCoating, glassCoating, glassCoatingDescription, mirrorSkiCoating, mirrorSkiCoatingOtherDescription, rightBaseCurve, leftBaseCurve, pressOnPrismValue, slabOffValue, nosePadsValue } = this.lensForm.controls;

    // Instantiate lab order information object if it doesn't exist
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) { this.activeClaim.labOrderInformation = {} as LabOrderInformation; }

    this.activeClaim.labOrderInformation.lensFinishing = finishing.value;

    // Lazy init the lens, if no lens if found on the active claim
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens)) {
      this.activeClaim.labOrderInformation.lens = { } as SpectacleLens;
    }

    // Find the user selected vision, material, and lens
    const matchedVisionType: LensVisionType = isEmptyList(this.lensVisionTypes) || isNullOrUndefined(visionType.value) ? undefined : this.lensVisionTypes.find((type: LensVisionType) => type.externalId === this.visionTypeFormValue);
    const matchedMaterialType: LensMaterialType = isEmptyList(this.lensMaterialTypes) || isNullOrUndefined(material.value) ? undefined : this.lensMaterialTypes.find((type: LensMaterialType) => type.externalId === this.materialFormValue);
    const matchedLens: LensType = isEmptyList(this.lensTypes) || isNullOrUndefined(lens.value) ? undefined : this.lensTypes.find((type: LensType) => type.externalId === this.lensFormValue); // TODO: this might changes based on lab order
    const oneLensValue: string = isNullOrUndefined(oneLens.value) ? undefined : oneLens.value;
    const balanceLensValue: string = isNullOrUndefined(balanceLens.value) ? undefined : balanceLens.value;

    // Checks to see if Lens field changed
    if (isNullOrUndefined(matchedLens)) {
      if (!isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.externalId)) {
        this.lensOrARCoatingChanged = true;
      }
    } else if (this.activeClaim.labOrderInformation.lens.externalId !== matchedLens.externalId) {
      this.lensOrARCoatingChanged = true;
    }

    // Update the active claim lens if the user has updated the value
    this.activeClaim.labOrderInformation.lens.externalId = isNullOrUndefined(matchedLens) ? undefined : matchedLens.externalId;
    this.activeClaim.labOrderInformation.lens.visionType = isNullOrUndefined(matchedVisionType) ? undefined : { name: matchedVisionType.name, externalId: matchedVisionType.externalId } as LensVisionType;
    this.activeClaim.labOrderInformation.lens.materialType = isNullOrUndefined(matchedMaterialType) ? undefined : { name: matchedMaterialType.name, externalId: matchedMaterialType.externalId } as LensMaterialType;
    this.activeClaim.labOrderInformation.lensBaseCurveRight = isNullOrUndefined(rightBaseCurve) ? undefined : rightBaseCurve.value;
    this.activeClaim.labOrderInformation.lensBaseCurveLeft = isNullOrUndefined(leftBaseCurve) ? undefined : leftBaseCurve.value;
    this.activeClaim.labOrderInformation.oneLens = oneLensValue;
    this.activeClaim.labOrderInformation.balanceLens = balanceLensValue;
    this.setBevelInActiveClaim(bevel.value);
    this.setEdgeInActiveClaim(edge.value);
    this.setDyeColorInActiveClaim(dyeColor.value, dyeColorOther.value);
    this.activeClaim.labOrderInformation.lensTintSample = isNullOrUndefined(dyeDetails.value) ? undefined : dyeDetails.value;
    this.activeClaim.labOrderInformation.lensTintSamplePercentage = isNullOrUndefined(dyeLightenDarkenPercent.value) ? undefined : dyeLightenDarkenPercent.value;
    this.setDyeTypeInActiveClaim(dyeType.value);
    this.setARCoatingInActiveClaim(arCoating.value);
    this.setUVCoatingInActiveClaim(uvCoating.value);
    this.setScratchCoatingInActiveClaim(scratchCoating.value);
    this.setGlassCoatingInActiveClaim(glassCoating.value, glassCoatingDescription.value);
    this.setMirrorSkiCoatingInActiveClaim(mirrorSkiCoating.value, mirrorSkiCoatingOtherDescription.value);
    this.activeClaim.labOrderInformation.pressOnPrism = (!isNullOrUndefined(pressOnPrismValue.value)) ? { code: MiscellaneousServices.PressOnPrismCode, description: pressOnPrismValue.value } as LabOrderOption : undefined;
    this.activeClaim.labOrderInformation.nosePads = (!isNullOrUndefined(nosePadsValue.value)) ? { code: MiscellaneousServices.NosePadsCode, description: nosePadsValue.value } as LabOrderOption : undefined;
    this.activeClaim.labOrderInformation.slabOff = (!isNullOrUndefined(slabOffValue.value)) ? { code: MiscellaneousServices.SlabOffCode, description: slabOffValue.value} as LabOrderOption : undefined;

    // If the lens value has been cleared/disabled either programmatically, or by the user, broadcast the event for
    // the prescription customizable lens fields. Broadcasting undefined, rather than a wear value, will disable the fields
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens.externalId)) {
      this.viewStateService.setSelectedSpectacleLens(undefined);
    }

    // ECR-9672 we can set the active claim without checking if the values changed as this card doesn't fuel the cyclical behavior from before
    this.claimService.setActiveClaim(this.activeClaim, this.id);
  }

  private setBevelInActiveClaim(selectedBevel: Bevel): void {
    if (isNullOrUndefined(selectedBevel)) {
      this.activeClaim.labOrderInformation.lensBevel = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensBevel)) {
      this.activeClaim.labOrderInformation.lensBevel = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensBevel.labServiceId = selectedBevel.labServiceId;
    this.activeClaim.labOrderInformation.lensBevel.labServiceDescription = selectedBevel.labServiceDescription;
    if (!isNullOrUndefined(selectedBevel.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensBevel.formularyEnhancements =
        selectedBevel.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setEdgeInActiveClaim(selectedEdge: EdgeCoating): void {
    if (isNullOrUndefined(selectedEdge)) {
      this.activeClaim.labOrderInformation.lensEdge = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensEdge)) {
      this.activeClaim.labOrderInformation.lensEdge = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensEdge.labServiceId = selectedEdge.labServiceId;
    this.activeClaim.labOrderInformation.lensEdge.labServiceDescription = selectedEdge.labServiceDescription;
    if (!isNullOrUndefined(selectedEdge.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensEdge.formularyEnhancements =
        selectedEdge.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setDyeColorInActiveClaim(selectedDyeColor: TintColor, dyeNotListed: string): void {
    if (isNullOrUndefined(selectedDyeColor)) {
      this.activeClaim.labOrderInformation.lensTintColor = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensTintColor)) {
      this.activeClaim.labOrderInformation.lensTintColor = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensTintColor.labServiceId = selectedDyeColor.labServiceId;
    this.activeClaim.labOrderInformation.lensTintColor.labServiceDescription = selectedDyeColor.labServiceId === TintColors.OTHER ? dyeNotListed : undefined;
    if (!isNullOrUndefined(selectedDyeColor.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensTintColor.formularyEnhancements =
        selectedDyeColor.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setDyeTypeInActiveClaim(selectedDyeType: DyeType): void {
    if (isNullOrUndefined(selectedDyeType)) {
      this.activeClaim.labOrderInformation.lensTintType = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensTintType)) {
      this.activeClaim.labOrderInformation.lensTintType = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensTintType.labServiceId = selectedDyeType.labServiceId;
    this.activeClaim.labOrderInformation.lensTintType.labServiceDescription = selectedDyeType.labServiceDescription;
    if (!isNullOrUndefined(selectedDyeType.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensTintType.formularyEnhancements =
        selectedDyeType.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setARCoatingInActiveClaim(selectedARCoating: ARCoating): void {
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.labSpecialInstructions)) {
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
    }

    if (isNullOrUndefined(selectedARCoating)) {
      // Checks to see if AR Coating selection changed
      if (!isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating)) {
        if (!isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId)) {
          this.lensOrARCoatingChanged = true;
        }
      }

      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = undefined;
      this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = false;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating)) {
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
    }

    // Checks to see if AR Coating selection changed
    if (this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId !== selectedARCoating.labServiceId) {
      this.lensOrARCoatingChanged = true;
    }

    this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId = selectedARCoating.labServiceId;
    this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceDescription = selectedARCoating.brandDescription;

    if (this.activeClaim.labOrderInformation.labSpecialInstructions.type === LabSpecialInstructionsType.BACKSIDE) {
      this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = true;
    } else {
      this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = false;
    }

    if (!isNullOrUndefined(selectedARCoating.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.formularyEnhancements =
        selectedARCoating.vspFormularyAssessment.formularyEnhancements.map(function (item) {
          return item['code'];
        });
    }
  }

  private setUVCoatingInActiveClaim(selectedUVCoating: UVCoating): void {
    if (isNullOrUndefined(selectedUVCoating)) {
      this.activeClaim.labOrderInformation.lensUltraVioletCoating = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensUltraVioletCoating)) {
      this.activeClaim.labOrderInformation.lensUltraVioletCoating = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensUltraVioletCoating.labServiceId = selectedUVCoating.labServiceId;
    this.activeClaim.labOrderInformation.lensUltraVioletCoating.labServiceDescription = selectedUVCoating.labServiceDescription;
    if (!isNullOrUndefined(selectedUVCoating.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensUltraVioletCoating.formularyEnhancements =
        selectedUVCoating.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setScratchCoatingInActiveClaim(selectedScratchCoating: ScratchCoating): void {
    if (isNullOrUndefined(selectedScratchCoating)) {
      this.activeClaim.labOrderInformation.lensScratchCoating = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensScratchCoating)) {
      this.activeClaim.labOrderInformation.lensScratchCoating = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensScratchCoating.labServiceId = selectedScratchCoating.labServiceId;
    this.activeClaim.labOrderInformation.lensScratchCoating.labServiceDescription = selectedScratchCoating.brandDescription;
    if (!isNullOrUndefined(selectedScratchCoating.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensScratchCoating.formularyEnhancements =
        selectedScratchCoating.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setGlassCoatingInActiveClaim(selectedGlassCoating: GlassCoating, glassCoatingDescription: string): void {
    if (isNullOrUndefined(selectedGlassCoating)) {
      this.activeClaim.labOrderInformation.lensGlassCoating = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensGlassCoating)) {
      this.activeClaim.labOrderInformation.lensGlassCoating = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensGlassCoating.labServiceId = selectedGlassCoating.labServiceId;
    this.activeClaim.labOrderInformation.lensGlassCoating.labServiceDescription = glassCoatingDescription;
    if (!isNullOrUndefined(selectedGlassCoating.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensGlassCoating.formularyEnhancements =
        selectedGlassCoating.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private setMirrorSkiCoatingInActiveClaim(selectedMirrorSkiCoating: MirrorSkiCoating, mirrorSkiCoatingOtherDescription: string): void {
    if (isNullOrUndefined(selectedMirrorSkiCoating)) {
      this.activeClaim.labOrderInformation.lensMirrorSkiCoating = undefined;
      return;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensMirrorSkiCoating)) {
      this.activeClaim.labOrderInformation.lensMirrorSkiCoating = { } as LabOrderLabServices;
    }

    this.activeClaim.labOrderInformation.lensMirrorSkiCoating.labServiceId = selectedMirrorSkiCoating.labServiceId;
    this.activeClaim.labOrderInformation.lensMirrorSkiCoating.labServiceDescription = mirrorSkiCoatingOtherDescription;
    if (!isNullOrUndefined(selectedMirrorSkiCoating.vspFormularyAssessment.formularyEnhancements)) {
      this.activeClaim.labOrderInformation.lensMirrorSkiCoating.formularyEnhancements =
        selectedMirrorSkiCoating.vspFormularyAssessment.formularyEnhancements.map(function(item) {
          return item['code'];
        });
    }
  }

  private enableDisableDyeRelatedFields(materialType: LensMaterialType): void {
    if (materialType && (materialType.name === LensMaterials.GLASS || materialType.name === LensMaterials.GLASS_HI_INDEX)) {
      this.lensForm.controls.dyeColor.disable();
      this.lensForm.controls.dyeType.disable();
      this.lensForm.controls.dyeColorOther.disable();
      this.lensForm.controls.dyeDetails.disable();
      this.lensForm.controls.dyeLightenDarkenPercent.disable();

      // Resetting the values when disabling the fields
      this.lensForm.controls.dyeColor.reset();
      this.lensForm.controls.dyeType.reset();
      this.lensForm.controls.dyeColorOther.reset();
      this.lensForm.controls.dyeDetails.reset();
      this.lensForm.controls.dyeLightenDarkenPercent.reset();
    } else {
      this.lensForm.controls.dyeColor.enable();
      this.lensForm.controls.dyeType.enable();
      this.lensForm.controls.dyeDetails.enable();
      this.onTintColorChange();
      this.onDyeDetailsChange();
    }
  }

  private enableDisableGlassCoating(materialType: LensMaterialType): void {
    if (materialType && materialType.name !== LensMaterials.GLASS && materialType.name !== LensMaterials.GLASS_HI_INDEX) {
      this.lensForm.controls.glassCoating.disable();
      this.lensForm.controls.glassCoatingDescription.disable();
      // Resetting the values when disabling the fields
      this.lensForm.controls.glassCoating.reset();
      this.lensForm.controls.glassCoatingDescription.reset();
    } else {
      this.lensForm.controls.glassCoating.enable();
      this.lensForm.controls.glassCoatingDescription.enable();
    }
  }

  private enableDisableScratchCoating() {
    const arCoatingType = this.lensForm.controls.arCoating.value;
    // Per ECR-11160 we are no longer disabling the Scratch Coating field when 'Polycarbonate' is selected in the Material Type dropdown
    // Per ECR-11224 we are no longer disabling the Scratch Coating field when ANY material type is selected. Only disabling it when the AR coat field is selected
    if (!isNullOrUndefined(arCoatingType)) {
      this.lensForm.controls.scratchCoating.disable();
      this.lensForm.controls.scratchCoating.reset();
    } else {
      this.lensForm.controls.scratchCoating.enable();
    }
  }

  private enableDisableARCoating(): void {
    const scratchCoating = this.lensForm.controls.scratchCoating.value;
    if (!isNullOrUndefined(scratchCoating) || (this.isIOFChannel() && !this.hasARItems ) ) {
      this.lensForm.controls.arCoating.disable();
      this.lensForm.controls.arCoating.reset();
    } else {
      this.lensForm.controls.arCoating.enable();
    }
  }

  private disableEnableFields(): void {
    if (this.mask) {
      this.isAMaskUpdate = true;
      this.disableFormGroupComponents(this.lensForm);
      this.componentMask.show();
    } else {
      this.isAMaskUpdate = true;
      this.lensForm.enable();
      this.disableFormFields();
      this.componentMask.hide();
    }
  }

  private enableDisableMultipleLensFields(): void {
    const materialType = this.lensMaterialTypes.find((material: LensMaterialType) => material.externalId === this.materialFormValue);
    this.enableDisableDyeRelatedFields(materialType);
    this.enableDisableScratchCoating();
    this.enableDisableGlassCoating(materialType);
    this.enableDisableARCoating();
  }

  private reloadAllLensCatalogFields(dateOfService: string): void {
    if (!isNullOrUndefined(dateOfService)) {
      this.reloadScratchCoatings(dateOfService);
      this.reloadARCoatings(dateOfService);
      this.reloadTintColors(dateOfService);
      this.reloadMirrorSkiCoatings(dateOfService);
      this.reloadDyeTypes(dateOfService);
      this.reloadUVCoatings(dateOfService);
      this.reloadGlassCoatings(dateOfService);
      this.reloadEdgeCoatings(dateOfService);
      this.reloadBevels(dateOfService);
      this.reloadLabSummaries(dateOfService);
    }
  }

  private hasDateOfServiceChangedOnActiveClaimUpdate(): boolean {
    if (!isNullOrUndefined(this.activeClaim) && !isNullOrUndefined(this.claimService.getActiveClaim())) {
      const {dateOfService: dateOfServiceFromCard } = this.activeClaim;
      const dateOfServiceFromCardString = DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFromCard);
      const {dateOfService: dateOfServiceFromService } = this.claimService.getActiveClaim();
      const dateOfServiceFromServiceString = DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFromService);
      return (dateOfServiceFromCardString || undefined) !== (dateOfServiceFromServiceString || undefined);
    } else {
      return false;
    }
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  get activeClaim(): Claim {
    return this._activeClaim;
  }

  set activeClaim(claimToSet: Claim) {
    this._activeClaim = claimToSet;
  }

  get originalClaim(): Claim {
    return this._originalClaim;
  }

  set originalClaim(claimToSet: Claim) {
    this._originalClaim = claimToSet;
  }

  get externalServiceLocation(): ExternalServiceLocation {
    return this._externalServiceLocation;
  }

  set externalServiceLocation(serviceLocation: ExternalServiceLocation) {
    this._externalServiceLocation = serviceLocation;
  }

  isEmpty(formControlValue: any): boolean {
    return formControlValue === undefined || formControlValue === null || formControlValue === '';
  }

  get scratchCoatings(): ScratchCoating[] {
    return this._scratchCoatings;
  }

  set scratchCoatings(items: ScratchCoating[]) {
    this._scratchCoatings = items;
  }

  get arCoatingsCategories(): ARCoatingCategory[] {
    return this._arCoatingsCategories;
  }

  set arCoatingsCategories(items: ARCoatingCategory[]) {
    this._arCoatingsCategories = items;
  }

  get tintColors(): TintColor[] {
    return this._tintColors;
  }

  set tintColors(items: TintColor[]) {
    this._tintColors = items;
  }

  get mirrorSkiCoatings(): MirrorSkiCoating[] {
    return this._mirrorSkiCoatings;
  }

  set mirrorSkiCoatings(items: MirrorSkiCoating[]) {
    this._mirrorSkiCoatings = items;
  }

  get mirrorSkiCoatingsCategories(): MirrorSkiCoatingCategory[] {
    return this._mirrorSkiCoatingsCategories;
  }

  set mirrorSkiCoatingsCategories(items: MirrorSkiCoatingCategory[]) {
    this._mirrorSkiCoatingsCategories = items;
  }

  get loadingMirrorSkiCoatings(): boolean {
    return this._loadingMirrorSkiCoatings;
  }

  set loadingMirrorSkiCoatings(isLoading: boolean) {
    this._loadingMirrorSkiCoatings = isLoading;
  }

  get isOtherMirrorSkiCoating(): boolean {
    return this._isOtherMirrorSkiCoating;
  }

  set isOtherMirrorSkiCoating(isOther: boolean) {
    this._isOtherMirrorSkiCoating = isOther;
  }

  get dyeTypes(): DyeType[] {
    return this._dyeTypes;
  }

  set dyeTypes(items: DyeType[]) {
    this._dyeTypes = items;
  }

  get loadingDyeTypes(): boolean {
    return this._loadingDyeTypes;
  }

  set loadingDyeTypes(isLoading: boolean) {
    this._loadingDyeTypes = isLoading;
  }

  get uvCoatings(): UVCoating[] {
    return this._uvCoatings;
  }

  set uvCoatings(items: UVCoating[]) {
    this._uvCoatings = items;
  }

  get loadingUVCoatings(): boolean {
    return this._loadingUVCoatings;
  }

  set loadingUVCoatings(isLoading: boolean) {
    this._loadingUVCoatings = isLoading;
  }

  get glassCoatings(): GlassCoating[] {
    return this._glassCoatings;
  }

  set glassCoatings(items: GlassCoating[]) {
    this._glassCoatings = items;
  }

  get loadingGlassCoatings(): boolean {
    return this._loadingGlassCoatings;
  }

  set loadingGlassCoatings(isLoading: boolean) {
    this._loadingGlassCoatings = isLoading;
  }

  get edgeCoatings(): EdgeCoating[] {
    return this._edgeCoatings;
  }

  set edgeCoatings(items: EdgeCoating[]) {
    this._edgeCoatings = items;
  }

  get loadingEdgeCoatings(): boolean {
    return this._loadingEdgeCoatings;
  }

  set loadingEdgeCoatings(isLoading: boolean) {
    this._loadingEdgeCoatings = isLoading;
  }

  get bevels(): Bevel[] {
    return this._bevels;
  }

  set bevels(items: Bevel[]) {
    this._bevels = items;
  }

  get loadingBevels(): boolean {
    return this._loadingBevels;
  }

  set loadingBevels(isLoading: boolean) {
    this._loadingBevels = isLoading;
  }

  set isPlanoLensesCheckBoxChecked(value: boolean) {
    this._isPlanoLensesCheckBoxChecked = value;
  }

  get isPlanoLensesCheckBoxChecked(): boolean {
    return this._isPlanoLensesCheckBoxChecked;
  }

  get lensVisionTypes(): LensVisionType[] {
    return this._lensVisionTypes;
  }

  set lensVisionTypes(value: LensVisionType[]) {
    this._lensVisionTypes = value;
  }

  get lensMaterialTypes(): LensMaterialType[] {
    return this._lensMaterialTypes;
  }

  set lensMaterialTypes(value: LensMaterialType[]) {
    this._lensMaterialTypes = value;
  }

  get lensTypes(): LensType[] {
    return this._lensTypes;
  }

  set lensTypes(value: LensType[]) {
    this._lensTypes = value;
  }

  /* Convenience form getters */
  get finishingFormValue(): string {
    return this.lensForm.get('finishing').value as string;
  }

  get visionTypeFormValue(): number | undefined {
    return tryParseInt(this.lensForm.get('visionType').value);
  }

  get materialFormValue(): number | undefined {
    return tryParseInt(this.lensForm.get('material').value);
  }

  get lensFormValue(): string {
    return this.lensForm.get('lens').value as string;
  }

  get oneLens(): string {
    return this.lensForm.get('oneLens').value as string;
  }

  get balanceLens(): string {
    return this.lensForm.get('balanceLens').value as string;
  }

  get dyeDetails(): string {
    return this.lensForm.get('dyeDetails').value;
  }


  get meta() {
    return LensConstants.lensFormMeta;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  get getIOFExemptStateCodes() {
    return LensConstants.iofExemptStates;
  }

  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.hasARItems = false;

    // Listen on ESL first so we can call LCAT if the user has previously saved lens data
    this.observableSubscriptions.push(this.externalServiceLocationService.onExternalServiceLocation.subscribe((externalServiceLocation: ExternalServiceLocation) => {
      this.externalServiceLocation = externalServiceLocation;
      if (this.externalServiceLocation) {
        this.updateFinishingList();
      }
    }));

    this.originalClaim = this.claimService.getOriginalClaim();
    this.initializeLensCatalogData();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildInputMasks();
    // ECLAIM-191 - This portion was added to handle IOF KScope Lenses.
    this.initializeIOFKScopeValuesIfNecessary();

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      this.mask = mask;
      setTimeout(() => { this.disableEnableFields(); });
    }));

    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe(() => {
      if (this.hasDateOfServiceChangedOnActiveClaimUpdate()) {
        const {dateOfService: dateOfServiceFromService } = this.claimService.getActiveClaim();
        const dateOfServiceFromServiceString = DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFromService);
        this.reloadAllLensCatalogFields(dateOfServiceFromServiceString);
      }
      this.activeClaim = this.claimService.getActiveClaim();
    }));

    // Register changes on the mirrorSkiCoating form control to conditionally display the text box for the 'Other' value
    this.observableSubscriptions.push(this.lensForm.get('mirrorSkiCoating').valueChanges.subscribe((dropdownValue: MirrorSkiCoating) => {
      const otherDescriptionControlReference: AbstractControl = this.lensForm.get('mirrorSkiCoatingOtherDescription');
      this.isOtherMirrorSkiCoating = !isNullOrUndefined(dropdownValue) && dropdownValue.labServiceDescription === ApplicationConstants.otherMirror;
      if (this.isOtherMirrorSkiCoating) {
        otherDescriptionControlReference.enable();
      } else {
        otherDescriptionControlReference.reset();
        otherDescriptionControlReference.disable();
      }
      this.changeDetector.detectChanges();
    }));

    // Register the plano Lenses checkbox changes from the Prescription card
    this.observableSubscriptions.push(this.viewStateService.onIsPlanoLensesCheckBoxChecked.subscribe((isPlanoLensesCheckBoxChecked: boolean) => {
      this.isPlanoLensesCheckBoxChecked = isPlanoLensesCheckBoxChecked;
      this.onPlanoLensesCheckboxChange();
    }));

    this.observableSubscriptions.push(this.lensForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      if (!this.isAMaskUpdate) {
        this.updateDataModelFromViewModel();
      }
      this.isAMaskUpdate = false;
    }));

    this.observableSubscriptions.push(this.viewStateService.onLensOrFrameCheckboxRemovalConfirmation.subscribe((materialToRemove: LensOrFrame) => {
      if (materialToRemove === LensOrFrame.Lens) {
        const controlsToStayDisabled: AbstractControl[] = [
          this.lensForm.get('material'),
          this.lensForm.get('lens'),
          this.lensForm.get('dyeColorOther'),
          this.lensForm.get('dyeLightenDarkenPercent')
        ];

        // Reset the control values for each control on the form if the exam component made the corresponding emission
        Object.keys(this.lensForm.controls).forEach((controlName: string) => {
          const currentControl: AbstractControl = this.lensForm.get(controlName);

          // Reset the finishing type to 'Lab Finishing' if on current iteration
          if (!isNullOrUndefined(currentControl)) {
            switch (controlName) {
              case 'finishing':
                currentControl.setValue(LensFinishingTypes.LabFinishing, ApplicationConstants.updateFormWithoutEmit);
                break;
              case 'dyeDetails':
                currentControl.setValue(DyeDetailsTypes.NO_SAMPLE, ApplicationConstants.updateFormWithoutEmit);
                break;
              default:
                currentControl.reset();
                if (controlsToStayDisabled.includes(currentControl)) {
                  currentControl.disable(ApplicationConstants.updateFormWithoutEmit);
                } else {
                  currentControl.enable(ApplicationConstants.updateFormWithoutEmit);
                }
                break;
            }
          }
        });
        this.lensForm.updateValueAndValidity();
        this.updateDataModelFromViewModel();
        this.changeDetector.detectChanges();
        this.viewStateService.setMaterialToRemoveState(null);
      }
    }));

    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }

    }));

    // TODO:  After plumbing services, revisit this logic
    // if claimFormLabService.isStockLensesSelected($scope.claim) --> this.lensForm.controls.visionType needs to be disabled
    // if $scope.claim.prescription.planoLenses is true --> this.lensForm.controls.oneLens needs to be disabled
    // if $scope.claim.prescription.planoLenses is true --> this.lensForm.controls.balanceLens needs to be disabled
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    setTimeout(() => { this.disableEnableFields(); });
  }

  onFinishingChange(): void {
    if (this.finishingFormValue === LensFinishingTypes.InOfficeStockLenses) {
      const singleVisionExternalId: number = this.lensVisionTypes.find((type: LensVisionType) => LensVisionTypes.SINGLE_VISION === type.name).externalId;
      this.lensForm.controls.visionType.setValue(singleVisionExternalId);
      this.onVisionTypeChange();
    } else {
      this.lensForm.controls.visionType.reset();
      this.lensForm.controls.material.reset();

      this.lensForm.controls.material.disable();
      this.lensForm.controls.material.clearValidators();
      this.lensForm.controls.material.updateValueAndValidity();
    }
    const {dateOfService: dateOfServiceFromService } = this.claimService.getActiveClaim();
    const dateOfServiceFromServiceString = DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFromService);
    this.reloadARCoatings(dateOfServiceFromServiceString);

    this.lensForm.controls.visionType.enable();
    this.lensForm.controls.visionType.updateValueAndValidity();

    this.lensForm.controls.lens.reset();
    this.lensForm.controls.lens.disable();
    this.lensForm.controls.lens.clearValidators();
    this.lensForm.controls.lens.updateValueAndValidity();

    this.enableDisableMultipleLensFields();

    // ECLAIM-191 - This portion was added to handle IOF KScope Lenses.
    this.initializeIOFKScopeValuesIfNecessary();

    // TODO: this is what it used to do
    // if Finishing is "In-Office Stock Lenses" set the Vision Type to Single Vision
    // if (claimFormLabService.isStockLensesSelected($scope.claim)){
    //   $scope.claim.lens.visionType = 'Single Vision';
    // }
  }

  /**
   * ECLAIM-191 - This method was added to handle the lens finishing changes.
   */
  initializeIOFKScopeValuesIfNecessary() {
    if (this.userSessionService.isKaleyedoscopePractice) {
      // This flag checks to see if we can or cannot clear the frame on a lens finishing change.
      // It is only set to true when all the cards have fully loaded
      // (look for the onAllClaimFormCardsHaveLoaded observable subscription and setFocusOnFrameCard method).
      if (this.fulfillmentService.canFrameBeClearedOnLensFinishingChange) {
        // Note we only want to clear out the frame if we are going from Lens Finishing to IO and from IOF to LKens Finishing.
        // We do not want to clear it out when we are going from IOF to IOF Uncut
        let switchingFromIofToUncutOrViceVersa: boolean = false;
        if ((this.finishingFormValue === LensFinishingTypes.InOfficeStockLenses && this.fulfillmentService.isInOfficeUncutLens)
              || (this.finishingFormValue === LensFinishingTypes.InOfficeUncutLenses && this.fulfillmentService.isInOfficeStockLens)) {
          switchingFromIofToUncutOrViceVersa = true;
        }
        if (!switchingFromIofToUncutOrViceVersa) {
          this.viewStateService.setMaterialToRemoveState(LensOrFrame.Frame);
        }
      }
      // Reset to original value.
      this.fulfillmentService.isInOfficeStockLens = false;
      this.fulfillmentService.isInOfficeUncutLens = false;
      if (this.finishingFormValue === LensFinishingTypes.InOfficeStockLenses) {
        this.fulfillmentService.isInOfficeStockLens = true;
      } else if (this.finishingFormValue === LensFinishingTypes.InOfficeUncutLenses) {
        this.fulfillmentService.isInOfficeUncutLens = true;
      }
    }
  }

  onVisionTypeChange(): void {
    this.lensForm.controls.material.reset();
    this.lensForm.controls.lens.reset();
    this.lensForm.controls.lens.disable();
    if (!isNullOrUndefined(this.visionTypeFormValue)) {
      this.lensForm.controls.material.enable();
      this.lensForm.controls.material.setValidators(Validators.required);
      this.lensForm.controls.lens.setValidators(Validators.required);
    } else {
      this.lensForm.controls.material.disable();
      this.lensForm.controls.material.clearValidators();
      this.lensForm.controls.lens.clearValidators();
    }
    this.enableDisableMultipleLensFields();

    this.lensForm.controls.material.updateValueAndValidity();
  }

  onARCoatingChange(): void {
    this.enableDisableScratchCoating();
  }

  onMaterialChange(): void {
    this.enableDisableMultipleLensFields();

    this.lensForm.controls.lens.reset();
    if (!isNullOrUndefined(this.materialFormValue)) {
      this.lensForm.controls.lens.setValidators(Validators.required);
      this.loadLenses();
    } else {
      this.lensForm.controls.lens.disable();
      this.lensForm.controls.lens.clearValidators();
    }
    this.lensForm.controls.lens.updateValueAndValidity();
  }

  onLensChange(): void {
    // Match on the selected lens
    const selectedLens: LensType = this.lensTypes.find((lens: LensType) => lens.externalId === this.lensFormValue);

    // Follow the link to retrieve lens details, if available
    if (selectedLens && selectedLens.self) {
      this.retrieveLensAndBroadcastPositionOfWearToListeners(selectedLens);
    }
  }

  onOneLensChange(): void {
    const oneLens = this.oneLens;
    if (oneLens === RightLeftEnum.RIGHT || oneLens === RightLeftEnum.LEFT) {
      this.lensForm.controls.balanceLens.reset();
      this.lensForm.controls.balanceLens.disable();
    } else {
      this.lensForm.controls.balanceLens.enable();
    }
  }

  onBalanceLensChange(): void {
    const balanceLens = this.balanceLens;
    if (balanceLens === RightLeftEnum.RIGHT || balanceLens === RightLeftEnum.LEFT) {
      this.lensForm.controls.oneLens.reset();
      this.lensForm.controls.oneLens.disable();
    } else {
      this.lensForm.controls.oneLens.enable();
    }
  }

  onTintColorChange(): void {
    if (!isNullOrUndefined(this.lensForm.controls.dyeColor.value) && this.lensForm.controls.dyeColor.value.labServiceId === TintColors.OTHER) {
      this.lensForm.controls.dyeColorOther.enable();
    } else {
      this.lensForm.controls.dyeColorOther.reset();
      this.lensForm.controls.dyeColorOther.disable();
    }
  }

  onScratchCoatingChange(): void {
    this.enableDisableARCoating();
  }

  onScratchCoatingClick(): void {
    if (isNullOrUndefined(this.scratchCoatings) || !(this.scratchCoatings.length > 0)) {
      this.reloadScratchCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadScratchCoatings(dateOfService: string): void {
    this.loadingScratchCoatings = true;
    this.lensForm.get('scratchCoating').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.ScratchCoatings, dateOfService).subscribe((scratchCoatings: ScratchCoating[]) => {
      this.lensForm.get('scratchCoating').enable();
      this.scratchCoatings = scratchCoatings;
      this.loadingScratchCoatings = false;
      if (isNullOrUndefined(scratchCoatings)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('scratchCoating').setValue(this.getMatchingScratchCoatingFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  onARCoatingClick(): void {
    if (isNullOrUndefined(this.arCoatingsCategories) || !(this.arCoatingsCategories.length > 0)) {
      this.reloadARCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadARCoatings(dateOfService: string): void {
    this.loadingARCoatings = true;
    this.lensForm.get('arCoating').disable();
    let isIOFChannel = false;
    const channelName = this.getChannelName(this.finishingFormValue);
    if ( channelName === LensChannelNames.IOF ) {
      isIOFChannel = true;
    }

    this.lensService.lensLabServiceFactory(LensCatalogOperation.ARCoatings, dateOfService, isIOFChannel).subscribe((response) => {
      this.lensForm.get('arCoating').enable();
      this.arCoatingsCategories = this.getViewFriendlyARCoatings();
      this.loadingARCoatings = false;
      if ( !isNullOrUndefined(response) && !isNullOrUndefined(response.length) && response.length > 0 ) {
          this.lensForm.get('arCoating').setValue(this.getMatchingARCoatingFromOriginalClaim());
          this.hasARItems = true;
      } else {
        this.hasARItems = false;
        if ( !isIOFChannel ) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        }
      }
      this.enableDisableARCoating();
      this.changeDetector.detectChanges();
    });
  }

  getViewFriendlyARCoatings(): ARCoatingCategory[] {
    const arCoatingCategories: ARCoatingCategory[] = [];
    const arCoatingsFromAPI = this.lensService.arCoatings;
    if (arCoatingsFromAPI && arCoatingsFromAPI.length > 0) {
      for (const arCoatingItem of this.lensService.arCoatings) {
        if (arCoatingItem && arCoatingItem.vspFormularyAssessment && arCoatingItem.vspFormularyAssessment.arAssessmentCategory &&
          arCoatingItem.vspFormularyAssessment.arAssessmentCategory.indexOf('-') > -1) {
          const arCoatingCategoryForItem = `***AR Coating ${arCoatingItem.vspFormularyAssessment.arAssessmentCategory.split('-')[1]}***`;
          let arCoatingCategoryNotFound = true;
          for (const arCoatingCategory of arCoatingCategories) {
            if (arCoatingCategory.arCoatingCategoryLabel === arCoatingCategoryForItem) {
              arCoatingCategory.arCoatings.push(arCoatingItem);
              arCoatingCategoryNotFound = false;
            }
          }
          if (arCoatingCategoryNotFound) {
            const newARCoatingCategory: ARCoatingCategory = {
              arCoatingCategoryLabel: undefined,
              arCoatings: []
            };
            newARCoatingCategory.arCoatingCategoryLabel = arCoatingCategoryForItem;
            newARCoatingCategory.arCoatings.push(arCoatingItem);
            arCoatingCategories.push(newARCoatingCategory);
          }
        }
      }
      arCoatingCategories.sort( function(a: ARCoatingCategory, b: ARCoatingCategory) {
        if (a.arCoatingCategoryLabel < b.arCoatingCategoryLabel) { return -1; }
        if (a.arCoatingCategoryLabel > b.arCoatingCategoryLabel) { return 1; }
        return 0;
      });
      for (let i = 0; i < arCoatingCategories.length; i++) {
        arCoatingCategories[i].arCoatings.sort( function(a: ARCoating, b: ARCoating) {
          if (a.brandDescription.indexOf('Other') > -1) { return 1; }
          if (b.brandDescription.indexOf('Other') > -1) { return -1; }
          if (a.brandDescription.indexOf('Lab Choice') > -1) { return 1; }
          if (b.brandDescription.indexOf('Lab Choice') > -1) { return -1; }
          if (a.brandDescription < b.brandDescription) { return -1; }
          if (a.brandDescription > b.brandDescription) { return 1; }
          return 0;
        });
      }
    }
    return arCoatingCategories;
  }

  onTintColorClick(): void {
    if (isNullOrUndefined(this.tintColors) || !(this.tintColors.length > 0)) {
      this.reloadTintColors(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadTintColors(dateOfService: string): void {
    this.loadingTintColors = true;
    this.lensForm.get('dyeColor').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.TintColors, dateOfService).subscribe((tintColors: TintColor[]) => {
      this.lensForm.controls.dyeColor.enable();
      this.tintColors = tintColors;
      this.loadingTintColors = false;
      if (isNullOrUndefined(tintColors)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('dyeColor').setValue(this.getMatchingDyeColorFromOriginalClaim());
        this.onTintColorChange();
      }
      this.changeDetector.detectChanges();
    });
  }

  onMirrorSkiCoatingClick(): void {
    if (isNullOrUndefined(this.mirrorSkiCoatingsCategories) || this.mirrorSkiCoatingsCategories.length === 0) {
      this.reloadMirrorSkiCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadMirrorSkiCoatings(dateOfService: string): void {
    this.loadingMirrorSkiCoatings = true;
    this.lensForm.get('mirrorSkiCoating').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.MirrorSkiCoatings, dateOfService).subscribe((coatings: MirrorSkiCoating[]) => {
      this.lensForm.get('mirrorSkiCoating').enable();
      this.mirrorSkiCoatingsCategories = this.getViewFriendlyMirrorSkiCoatings();
      this.loadingMirrorSkiCoatings = false;
      if (isNullOrUndefined(coatings)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('mirrorSkiCoating').setValue(this.getMatchingMirrorSkiCoatingFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  toSlugId(value: string): string {
    return simpleSlugFromString(value);
  }

  getViewFriendlyMirrorSkiCoatings(): MirrorSkiCoatingCategory[] {
    const mirrorSkiCoatingCategories: MirrorSkiCoatingCategory[] = [];
    const mirrorSkiCoatingsFromAPI = this.lensService.mirrorSkiCoatings;
    if (mirrorSkiCoatingsFromAPI && mirrorSkiCoatingsFromAPI.length > 0) {
      for (const mirrorSkiCoatingItem of this.lensService.mirrorSkiCoatings) {
        if (mirrorSkiCoatingItem && mirrorSkiCoatingItem.vspFormularyAssessment && mirrorSkiCoatingItem.vspFormularyAssessment.miscellaneousAssessmentCategory) {
          const mirrorSkiCoatingCategoryForItem = `*** ${mirrorSkiCoatingItem.vspFormularyAssessment.miscellaneousAssessmentCategory} ***`;
          let mirrorSkiCoatingCategoryNotFound = true;
          for (const mirrorSkiCoatingCategory of mirrorSkiCoatingCategories) {
            if (mirrorSkiCoatingCategory.mirrorSkiCoatingCategoryLabel === mirrorSkiCoatingCategoryForItem) {
              mirrorSkiCoatingCategory.mirrorSkiCoatings.push(mirrorSkiCoatingItem);
              mirrorSkiCoatingCategoryNotFound = false;
            }
          }
          if (mirrorSkiCoatingCategoryNotFound) {
            const newMirrorSkiCoatingCategory: MirrorSkiCoatingCategory = {
              mirrorSkiCoatingCategoryLabel: undefined,
              mirrorSkiCoatings: []
            };
            newMirrorSkiCoatingCategory.mirrorSkiCoatingCategoryLabel = mirrorSkiCoatingCategoryForItem;
            newMirrorSkiCoatingCategory.mirrorSkiCoatings.push(mirrorSkiCoatingItem);
            mirrorSkiCoatingCategories.push(newMirrorSkiCoatingCategory);
          }
        }
      }
      mirrorSkiCoatingCategories.sort( function(a: MirrorSkiCoatingCategory, b: MirrorSkiCoatingCategory) {
        if (a.mirrorSkiCoatingCategoryLabel < b.mirrorSkiCoatingCategoryLabel) { return -1; }
        if (a.mirrorSkiCoatingCategoryLabel > b.mirrorSkiCoatingCategoryLabel) { return 1; }
        return 0;
      });
      for (let i = 0; i < mirrorSkiCoatingCategories.length; i++) {
        mirrorSkiCoatingCategories[i].mirrorSkiCoatings.sort( function(a: MirrorSkiCoating, b: MirrorSkiCoating) {
          if (a.labServiceDescription.indexOf('Other') > -1) { return 1; }
          if (b.labServiceDescription.indexOf('Other') > -1) { return -1; }
          return 0;
        });
      }
    }
    return mirrorSkiCoatingCategories;
  }

  onDyeTypeClick(): void {
    if (isNullOrUndefined(this.dyeTypes) || this.dyeTypes.length === 0) {
      this.reloadDyeTypes(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadDyeTypes(dateOfService: string): void {
    this.loadingDyeTypes = true;
    this.lensForm.get('dyeType').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.DyeTypes, dateOfService).subscribe((coatings: DyeType[]) => {
      this.lensForm.get('dyeType').enable();
      this.dyeTypes = coatings;
      this.loadingDyeTypes = false;
      if (isNullOrUndefined(coatings)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('dyeType').setValue(this.getMatchingDyeTypeFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  onDyeDetailsChange(): void {
    const dyeDetailsValue: string = this.dyeDetails;
    if (dyeDetailsValue === DyeDetailsTypes.LIGHTEN || dyeDetailsValue === DyeDetailsTypes.DARKEN) {
      this.lensForm.controls.dyeLightenDarkenPercent.enable();
    } else {
      this.lensForm.controls.dyeLightenDarkenPercent.reset();
      this.lensForm.controls.dyeLightenDarkenPercent.disable();
    }
  }

  onUVCoatingClick(): void {
    if (isNullOrUndefined(this.uvCoatings) || this.uvCoatings.length === 0) {
      this.reloadUVCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadUVCoatings(dateOfService: string): void {
    this.loadingUVCoatings = true;
    this.lensForm.get('uvCoating').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.UVCoatings, dateOfService).subscribe((coatings: UVCoating[]) => {
      this.lensForm.get('uvCoating').enable();
      this.uvCoatings = coatings;
      this.loadingUVCoatings = false;
      if (isNullOrUndefined(coatings)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('uvCoating').setValue(this.getMatchingUVCoatingFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  onGlassCoatingClick(): void {
    if (isNullOrUndefined(this.glassCoatings) || this.glassCoatings.length === 0) {
      this.reloadGlassCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadGlassCoatings(dateOfService: string): void {
      this.loadingGlassCoatings = true;
      this.lensForm.get('glassCoating').disable();
      this.lensService.lensLabServiceFactory(LensCatalogOperation.GlassCoatings, dateOfService).subscribe( (coatings: GlassCoating[]) => {
        this.lensForm.get('glassCoating').enable();
        this.glassCoatings = coatings;
        this.loadingGlassCoatings = false;
        if (isNullOrUndefined(coatings)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.lensForm.get('glassCoating').setValue(this.getMatchingGlassCoatingFromOriginalClaim());
        }
        this.changeDetector.detectChanges();
      });
    }

  onEdgeCoatingClick(): void {
    if (isNullOrUndefined(this.edgeCoatings) || this.edgeCoatings.length === 0) {
      this.reloadEdgeCoatings(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadEdgeCoatings(dateOfService: string): void {
    this.loadingEdgeCoatings = true;
    this.lensForm.get('edge').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.EdgeCoatings, dateOfService).subscribe( (coatings: EdgeCoating[]) => {
      this.lensForm.get('edge').enable();
      this.edgeCoatings = coatings;
      this.loadingEdgeCoatings = false;
      if (isNullOrUndefined(coatings)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('edge').setValue(this.getMatchingEdgeCoatingFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  onBevelClick(): void {
    if (isNullOrUndefined(this.bevels) || this.bevels.length === 0) {
      this.reloadBevels(DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService));
    }
  }

  reloadBevels(dateOfService: string): void {
    this.loadingBevels = true;
    this.lensForm.get('bevel').disable();
    this.lensService.lensLabServiceFactory(LensCatalogOperation.Bevels, dateOfService).subscribe( (items: Bevel[]) => {
      this.lensForm.get('bevel').enable();
      this.bevels = items;
      this.loadingBevels = false;
      if (isNullOrUndefined(items)) {
        this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
      } else {
        this.lensForm.get('bevel').setValue(this.getMatchingBevelFromOriginalClaim());
      }
      this.changeDetector.detectChanges();
    });
  }

  reloadLabSummaries(dateOfServiceString: string): void {
    if (!isNullOrUndefined(this.materialFormValue)) {
      const dateOfServiceReformatted = DateUtility.buildDateFromDateString(dateOfServiceString);
      const visionType: string = isNullOrUndefined(this.lensVisionTypes) ? undefined : this.lensVisionTypes.find((type: LensVisionType) => this.visionTypeFormValue === type.externalId).name;
      const materialType: string = isNullOrUndefined(this.lensVisionTypes) ? undefined : this.lensMaterialTypes.find((type: LensMaterialType) => this.materialFormValue === type.externalId).name;
      const finishingType: string = this.finishingFormValue;
      const channelName = this.getChannelName(finishingType);
      const lensId = this.lensForm.value.lens;

      this.loadingLens = true;
      this.lensService.loadLensTypes(channelName, visionType, materialType, dateOfServiceReformatted).subscribe((lensTypes: LensType[]) => {
        if (lensTypes) {
          this.lensTypes = lensTypes;
          if (this.lensForm.enabled) {
            this.lensForm.controls.lens.enable(ApplicationConstants.updateFormWithoutEmit);
          }
          this.lensForm.controls.lens.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
          if (!isStringNullUndefinedOrEmpty(lensId)) {
            // Grab a reference to the persisted lens from the lens values returned from LCAT search
            const selectedLens: LensType = lensTypes.find((lens: LensType) => lens.externalId === lensId);
            if (selectedLens && selectedLens.self) {
              // Retrieve the lens and broadcast to the prescription card
              this.retrieveLensAndBroadcastPositionOfWearToListeners(selectedLens, true);
            }
          }
        }
        this.loadingLens = false;
        this.changeDetector.detectChanges();
      });
    }
  }

  onPlanoLensesCheckboxChange() {
    const isPlanoLensesCheckboxChecked: boolean = this.isPlanoLensesCheckBoxChecked;
    if (isPlanoLensesCheckboxChecked === true) {
      this.lensForm.controls.oneLens.reset();
      this.lensForm.controls.oneLens.disable();
      this.lensForm.controls.balanceLens.reset();
      this.lensForm.controls.balanceLens.disable();
    } else {
      if (isNullOrUndefined(this.oneLens)) {
        this.lensForm.controls.balanceLens.enable();
      }
      if (isNullOrUndefined(this.balanceLens)) {
        this.lensForm.controls.oneLens.enable();
      }
    }
  }

  // get PracticeStateCode from Batch Cookie
  buildPracticeStateCodeFromBatchCookie() {
    const batchCookie = `BATCH=${this.cookieService.get('BATCH')}`;
    let practiceStateCode: string;
    if (batchCookie.includes('|')) {
      const batchSplit: string[] = batchCookie.toString().split('|');
      if (!isStringNullUndefinedOrEmptyWithTrim(batchSplit[3]) && batchSplit[3].includes('DR_STATE=')) {
        const stateCodeCookie: string[] = batchSplit[3].split('=');
        if (stateCodeCookie.length > 1) {
          practiceStateCode = stateCodeCookie[1];
        }
      }
    }
    return practiceStateCode;
  }

  /**
   * This method was added as part of ECLAIM-269 to clear out VisionType, Material and Lens fields in the claim form if Lens retrieve from PE is not inside the LCAT summaries response.
   *
   * @param lensExternalId - External ID we want to search for inside the LCAT Summaries response.
   */
  clearOutLensIfNotFoundInLCATResponse(lensExternalId: string) {
    if (!isStringNullUndefinedOrEmptyWithTrim(lensExternalId)) {
      // Look for lens lensExternalId in the LCAT Summaries response
      const lensExternalIdFound: LensType = this.lensTypes.find((lens: LensType) => lens.externalId === lensExternalId);
      if (isNullOrUndefined(lensExternalIdFound)) {
        // If it is not found in the LCAT Summaries response, then  clear out VisionType, Material and Lens Fields.
        // NOTE: To keep it clean we will be using the 'onFinishingChange' function to accomplish this which it is already
        // configured to do the necessary updates to the form.
        this.onFinishingChange();
        // Need to update the data model so the rest of the claim form cards get the update.
        this.lensForm.updateValueAndValidity();
        this.updateDataModelFromViewModel();
        this.changeDetector.detectChanges();
        this.viewStateService.setMaterialToRemoveState(null);
        // This will notify the 'SoftAndHardEditBannerComponent' component to show the edit banner.
        this.lensService.onShowLensExpiredEdit.next(true);
      }
    }
  }
  /***** END - EVENT HANDLERS *****/
}

import { BalanceLensTypes, LensFinishingTypes, OneLensTypes, RightLeftEnum, DyeDetailsTypes } from 'src/app/models/lens';
import { ReferenceListValue, ReferenceListViewModel } from '../../../models/reference-list-view-model';
import {ApplicationConstants} from '../../../common/constants/application.constants';

// NOTE: All of these are values are mocked until we get it linked to the APIs.
export const LensConstants = {
  rightBaseCurve: 'rightBaseCurve',
  leftBaseCurve: 'leftBaseCurve',
  finishingTypes: [
    new ReferenceListValue(LensFinishingTypes.LabFinishing, 'Lab Finishing').viewModel,
    new ReferenceListValue(LensFinishingTypes.InOfficeStockLenses, 'In-Office Stock Lens').viewModel,
    new ReferenceListValue(LensFinishingTypes.InOfficeUncutLenses, 'In-Office Uncut Lens').viewModel,
  ] as ReferenceListViewModel[],
  dyeDetails: [
    DyeDetailsTypes.NO_SAMPLE, DyeDetailsTypes.MATCH_SAMPLE, DyeDetailsTypes.LIGHTEN, DyeDetailsTypes.DARKEN
  ],
  LIGHTEN_DARKEN_PERCENT: 'dyeLightenDarkenPercent',
  glassCoatingDescription: 'glassCoatingDescription',
  pressOnPrismValue: 'pressOnPrismValue',
  nosePadsValue : 'nosePadsValue',
  slabOffValue: 'slabOffValue',
  safetyThicknessOptions: [
    'Basic Impact 3.0 monogram lenses',
    'High Impact 2.0 monogram lenses',
    'Non-Industrial 3.0 not monogrammed'
  ],
  oneLensOptions: [
    new ReferenceListValue(RightLeftEnum.RIGHT, OneLensTypes.rightLensOnly).viewModel,
    new ReferenceListValue(RightLeftEnum.LEFT, OneLensTypes.leftLensOnly).viewModel,
  ],
  balanceLensOptions: [
    new ReferenceListValue(RightLeftEnum.RIGHT, BalanceLensTypes.rightIsBalance).viewModel,
    new ReferenceListValue(RightLeftEnum.LEFT, BalanceLensTypes.leftIsBalance).viewModel
  ],
  binocularMonocularOptions: [
    {label: 'Binocular PD', value: 'Binocular'},
    {label: 'Monocular PD (to center of bridge)', value: 'Monocular'}
  ],
  heightMeasurementStartingPoints: [
    'Bottom of Frame',
    'Geometric Center'
  ],
  horizontalPrizmTypes: [
    'In',
    'Out'
  ],
  verticalPrizmTypes: [
    'Up',
    'Down'
  ],

  lensFormMeta: {
    inputId: {
      pressOnPrismValue: 'pressOnPrismValue',
      nosePadsValue: 'nosePadsValue',
      slabOffValue: 'slabOffValue'
    },
    formControlMaxLength: {
      pressOnPrismValue: 10,
      nosePadsValue: 10,
      slabOffValue: 10
    },
    regexp: {
      lensString: ApplicationConstants.lensValueStringRegex,
      lensChar: ApplicationConstants.lensFieldsPasteCharacterRegex,
    }
  },

  iofExemptStates : {
    stateCodes: [
      // Below 12 states were added for ECLAIM-77
      'AL', // Alabama
      'AR', // Arkansas
      'FL', // Florida
      'GA', // Georgia
      'ME', // Maine
      'NJ', // New Jersey
      'OH', // Ohio
      'OR', // Oregon
      'SD', // South Dakota
      'TX', // Texas
      'VT', // Vermont
      'WV', // West Virginia
      // Below 5 states were added for ECLAIM-223
      'IL', // Illinois
      'KS', // Kansas
      'MO', // Missouri
      'NV', // Nevada
      'UT'  // Utah
    ]
  }
};

export enum LensMaterials {
 GLASS = 'Glass',
 POLYCARBONATE = 'Polycarbonate',
 PLASTIC = 'Plastic',
 PLASTIC_HI_INDEX = 'Plastic Hi Index',
 GLASS_HI_INDEX = 'Glass Hi Index',
 PLASTIC_MID_INDEX = 'Plastic Mid Index',
 TRIVEX = 'Trivex'
}

export enum LensVisionTypes {
  NO_SELECTION = '',
  SINGLE_VISION = 'Single Vision',
  BIFOCAL = 'Bifocal',
  TRIFOCAL = 'Trifocal',
  DOUBLE = 'Double',
  PROGRESSIVE = 'Progressive',
  LENTICUALR_SINGLE_VISION = 'Lenticular Single Vision',
  LENTICUALR_BIFOCAL = 'Lenticular Bifocal',
  NEAR_VARIABLE_FOCUS = 'Near Variable Focus'
}

export enum TintColors {
  OTHER = 'OTHER'
}


